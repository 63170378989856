import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import '../../assets/css/scss/main.css';
import * as icons from 'react-bootstrap-icons';
import RightSideBanner from './RightSideBanner';
import { ForgetPasswordEmailSend } from '../../redux/auth-reducer/auth-service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


function ForgotPassword() {
  const [validated, setValidated] = useState(false);
  const [Email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleEmail = (e) => {
     setEmail(e.target.value)
  }

console.log()
  useEffect(() => {
    if (isButtonDisabled) {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);
    }
  }, [isButtonDisabled])


  const handleSubmit = async (event) => {
    setIsButtonDisabled(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    const data = {
      "email": Email
    }

    await ForgetPasswordEmailSend(data).then((res) => {
      if (res?.status == true) {

        toast.success(res?.message);

      } else {
        setEmail('');
        toast.error(res?.message)
      }
    });
    setValidated(true);



  };



  return (
    <>
      <ToastContainer />
      <div className='grop-section'>
        <div className='icon-back'>
          <a href='/Login'> <icons.ArrowLeft /></a>
        </div>
        <div className='group-reg'>

          <div className='item'>
            <div className='inner-item'>
              <div className='form-contnet'>
                <h1 className='heading'>Forgot Password</h1>
                <p>Enter your email to recover your password</p>
              </div>
              <Form noValidate validated={validated} className='Login-form' onSubmit={handleSubmit}>
                <Row className="mb-3">

                  <Form.Group as={Col} md="12" controlId="validationCustomFristname">
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                      <icons.Person className='persion-icon' />


                      <Form.Control className='email-input'
                        type="email"
                        name='Email'
                        value={Email}
                        onChange={handleEmail}
                        placeholder="Enter your Email"
                        aria-describedby="inputGroupPrepend"
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter a Email.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Col md="12 ">
                    <Button type="submit" disabled={isButtonDisabled} className='submit'>Submit</Button>
                  </Col>



                </Row>
              </Form>

            </div>
          </div>
          <RightSideBanner />
        </div>
      </div>
    </>
  )
}
export default ForgotPassword;