import React, { useEffect, useState } from "react";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import { Card, Col, Row, Form } from "react-bootstrap";
import { Table } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import { GetUser } from "../../utils/helper/helper";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../../Footer";
import {
  AuctionCount,
  AuctionRecent,
  GetNotificationMes,
  MangergetBid,
  SportTotalGet,
  TeamTotal,
  TournamentGetCount,
  UserGetId,
  UserGetTotal,
  AuctionDataFilterByDate,
} from "../../redux/auth-reducer/auth-service";
import { ChartAdd3 } from "../Outer/ChartAdd3";
import dayjs from "dayjs";
import { DatePicker } from 'rsuite';


export const SuperDashboard = () => {
  const [data, setData] = useState(false);
  const [totalUserData, setTotalUserData] = useState([]);
  const [totalSportData, setTotalSportData] = useState([]);
  const [totalTeamData, setTotalTeamData] = useState([]);
  const [totalAuctionCount, setTotalAuctionCount] = useState([]);
  const [totalTounamentData, setTotalTounamentData] = useState();
  const [userData, setUserData] = useState();
  const [totalBidManager, setTotalBidManager] = useState();
  const [itemList, setItemList] = useState();
  const [auctionItemList, setAuctionItemList] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState(0);
  const [liveEvents, setLiveEvents] = useState(0);
  const [closedEvents, setClosedEvents] = useState(0);
  const [notificationMes, setNotificationMes] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [itemLastData, setItemLastData] = useState([]);
  const [startAuctionDate, setStartAuctionDate] = useState("01/01/2024");
  const [endAuctionDate, setEndAuctionDate] = useState(dayjs(new Date()).format("MM/DD/YYYY"));


  const navigate = useNavigate();


  function sidebarfun() {
    setData(!data);
  }


  useEffect(() => {
    const isAuth = localStorage.getItem("role");
    if (isAuth == "Manager") {
    } else {
      navigate("/Login");
    }
  }, []);

  useEffect(() => {
    const GetTotalUser = async () => {
      await UserGetTotal().then((res) => {
        setTotalUserData(res?.data);
      });
    };
    GetTotalUser();
  }, []);

  useEffect(() => {
    async function UserGet() {
      await UserGetId().then((res) => {
        setUserData(res?.id);

      });
    }
    UserGet();
  }, []);

  useEffect(() => {
    const GetTotalTounament = async () => {
      await TournamentGetCount().then((res) => {
        setTotalTounamentData(res?.data);
      });
    };
    GetTotalTounament();
  }, []);

  useEffect(() => {
    const user = JSON.parse(GetUser());
    const GetManagerBidRecent = async () => {
      await MangergetBid(user?.userId).then((res) => {
        const firstThreeObjects = res?.data?.slice(0,5);
        setTotalBidManager(firstThreeObjects);

      });
    };
    GetManagerBidRecent();
  }, [userData]);

  useEffect(() => {
    const GetTotalSport = async () => {
      await SportTotalGet().then((res) => {
        setTotalSportData(res?.data);

      });
    };
    GetTotalSport();
  }, []);

  useEffect(() => {
    const GetTotalTeam = async () => {
      await TeamTotal().then((res) => {
        setTotalTeamData(res?.data);

      });
    };
    GetTotalTeam();
  }, []);

  useEffect(() => {
    const GetAuctionCount = async () => {
      await AuctionCount().then((res) => {
        setTotalAuctionCount(res);
      });
    };
    GetAuctionCount();
  }, []);



  useEffect(() => {
    const AuctionAllFn = async () => {
      await AuctionRecent().then((response) => {
        const firstThreeObjects = response?.data.slice(0, 5);
        setItemList(firstThreeObjects);
      });
    };
    AuctionAllFn();
  }, []);

  useEffect(() => {
    notificationMes?.map((item) => {
      var user = item?.userName.split(" ");
      var dataList = user?.[0];
      var dataList2 = user?.[1];
      var listItem = dataList.charAt(0);
      var listItem2 = dataList2.charAt(0);
      setItemData(listItem);
      setItemLastData(listItem2);
    });
  }, [notificationMes]);

  useEffect(() => {
    const NotificationMessageFun = async () => {
      await GetNotificationMes().then((response) => {
        const firstThreeObjects = response?.data?.dataList.slice(0, 9);
        setNotificationMes(firstThreeObjects);
      });
    };
    NotificationMessageFun();
  }, []);


  const handelStartDate = async (event) => {
    setStartAuctionDate(dayjs(event).format("MM/DD/YYYY"))
  };
  const handelEndDate = async (event) => {
    setEndAuctionDate(dayjs(event).format("MM/DD/YYYY"))
  };

  useEffect(() => {
    if (startAuctionDate && endAuctionDate) {

      const AuctionFilter = async () => {
        await AuctionDataFilterByDate(startAuctionDate, endAuctionDate).then((response) => {
          setAuctionItemList(response?.data)

        });
      };
      AuctionFilter();

    }
  }, [startAuctionDate, endAuctionDate])


  useEffect(() => {

    const findUpcomingEvents = (eventArray) => {
      return eventArray?.filter((event) => event.status === "Upcoming")
        .length;
    };
    setUpcomingEvents(findUpcomingEvents(auctionItemList));

    const findLiveEvents = (eventArray) => {
      return eventArray?.filter((event) => event.status === "Live").length;
    };
    setLiveEvents(findLiveEvents(auctionItemList));

    const findClosedEvents = (eventArray) => {
      return eventArray?.filter((event) => event.status === "Closed").length;
    };
    setClosedEvents(findClosedEvents(auctionItemList));
  }, [
    auctionItemList,
    liveEvents, upcomingEvents, closedEvents
  ]);



  const convertUTCToLocalTime = (utcDateString) => {
    const date = new Date(utcDateString);
    return date.toLocaleString();
  };
  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group">
        <Sidebar data={data} />
        <div
          className={
            data ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >
          <Row>
            <h1 className="heading-dash">Manager Dashboard</h1>
          </Row>
          <Row className="dashboard-man">
            <Col>
              <Link to="/SuperAdmin/UserSuper" className="Link-to-page">
                <div className="item-list mb-md-3">
                  <div className="group-list1">
                    <div className="image-icon">
                      <img src="../image/user.svg" alt="user"></img>
                    </div>
                    <div className="content">
                      <h2>{totalUserData?.totalCount == null ? "0" : totalUserData?.totalCount}</h2>
                      <p>Players</p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to="/SuperAdmin/Auction" className="Link-to-page">
                <div className="item-list">
                  <div className="group-list1">
                    <div className="image-icon1">
                      <img src="../image/oragnization.svg"></img>
                    </div>
                    <div className="content">
                      <h2>{totalAuctionCount?.totalCount == null ? "0" : totalAuctionCount?.totalCount}</h2>
                      <p>Auctions</p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to="/SuperAdmin/Sports" className="Link-to-page">
                <div className="item-list">
                  <div className="group-list1">
                    <div className="image-icon2">
                      <img src="../image/ball.png" alt="user"></img>
                    </div>
                    <div className="content">
                      <h2>{totalSportData?.totalCount == null ? "0" : totalSportData?.totalCount}</h2>
                      <p>Sports</p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to="/SuperAdmin/Tournament" className="Link-to-page">
                <div className="item-list">
                  <div className="group-list1">
                    <div className="image-icon3">
                      <img src="../image/trophy.png" alt="user"></img>
                    </div>
                    <div className="content">
                      <h2>{totalTounamentData?.totalCount == null ? "0" : totalTounamentData?.totalCount}</h2>
                      <p>Tournaments</p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col>
              <Link to="/SuperAdmin/Team" className="Link-to-page">
                <div className="item-list">
                  <div className="group-list1">
                    <div className="image-icon4">
                      <img src="../image/team.png" alt="user"></img>
                    </div>
                    <div className="content">
                      <h2>{totalTeamData?.totalCount == null ? "0" : totalTeamData?.totalCount}</h2>

                      <p>Teams</p>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col md="12" lg="9">
              <Row>
                <Col md={12} lg="5">
                  <Card className="Bidder-sec mt-lg-4 mt-md-0">
                    <Row>
                      <Col md="6" xs="6">
                        <h2>Bidder Activity</h2>
                      </Col>
                      <Col md="6" xs="6">
                        <p className="text-right">
                          <Link className="view-List">View All</Link>
                        </p>
                      </Col>

                      <Col md="12 ">
                      <div className=" bid-table">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Auction Name</th>
                              <th>Team Name</th>
                              <th>Bid Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {totalBidManager?.map((listItem) => (
                              <tr>
                                <td>{listItem?.auctionName}</td>
                                <td>{listItem?.teamName}</td>
                                <td>{listItem?.currentBidAmount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col lg={7} md="12" className="mt-4 chart">
                  <Card className="Chart-sec pb-5">
                    <h3>Auction Statistics</h3>
                    <Row>
                      <Col>
                        <Form.Group >
                          <Form.Label>From</Form.Label>
                          <DatePicker placeholder="Start Date" format="MM/dd/yyyy" name="startDate" className=' Auction-Date' onChange={handelStartDate} required defaultValue={new Date('2024-01-01')} />
                          <Form.Control.Feedback type="invalid">
                            Please enter Auction Start Date.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>To</Form.Label>
                          <DatePicker placeholder="End Date" format="MM/dd/yyyy" name="endDate" className=' Auction-Date' onChange={handelEndDate} required defaultValue={new Date()} />
                          <Form.Control.Feedback type="invalid">
                            Please enter Auction End Date.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div>
                      {
                        liveEvents == 0 && upcomingEvents == 0 && closedEvents == 0 ? (<>
                          <div>
                            <h4>No data found</h4>
                          </div></>) : (
                          <Row>
                            <Col md={6}>
                              <div className="group-chart">
                                <ChartAdd3
                                  live={liveEvents}
                                  closed={closedEvents}
                                  upcoming={upcomingEvents}
                                />
                                <img
                                  src="../image/Group-11553.png"
                                  className="image-auction"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="list-item">
                                <Col className="mt-3">
                                  <div className="d-flex mb-3">
                                    <div className="list-circle me-2"></div>{" "}
                                    <p className="text-color-tab">
                                      Auction In-Progress: {liveEvents}
                                    </p>
                                  </div>
                                </Col>
                                <Col className="mt-3">
                                  <div className="d-flex mb-3">
                                    <div className="list-circle-pro  me-2"></div>
                                    <p className="text-color-tab">
                                      Auction Closed: {closedEvents}
                                    </p>
                                  </div>
                                </Col>
                                <Col className="mt-3">
                                  <div className="d-flex mb-3">
                                    <div className="list-circle-up  me-2"></div>
                                    <p className="text-color-tab">
                                      Upcoming Auction: {upcomingEvents}
                                    </p>
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </Row>
                        )
                      }

                    </div>
                  </Card>
                </Col>
              </Row>
              <Card className="p-3 mt-4 announcementtab">
                <Row>
                  <Col md="6" xs="6">
                    <h2 className="heading_sumary">Auction Summary</h2>
                  </Col>
                  <Col md="6" xs="6">
                    <p className="text-right">
                      <Link to="/SuperAdmin/Auction" className="view-List">
                        View All
                      </Link>
                    </p>
                  </Col>
                </Row>

                <div className=" bid-table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Auction Name</th>
                        <th>Sport</th>

                        <th>Start Date </th>
                        <th>Start Time</th>
                        <th>Tournament Name</th>
                        <th>Bid Time Limit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemList?.map((item) => {
                        const localTime = convertUTCToLocalTime(item?.startDate + "Z");
                        const [datePart, timePart] = localTime.split(", ");
                        return (
                          <>
                            <tr>
                              <td>{item?.auctionName}</td>
                              <td>{item?.sportsName}</td>

                              <td>{dayjs(item?.startDate).format("MM/DD/YYYY")}</td>
                              <td>
                                {timePart}


                              </td>
                              <td>{item?.tournamentName}</td>
                              <td>{item?.bidTime}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>

            <Col lg={3} md="12" className="mt-4 chart">
              <Card className="p-3 notification-dash" >
                <Row>
                  <Col md="6" xs="6">
                    <h2 className="heading_sumary">Notifications</h2>
                  </Col>
                  <Col md="6" xs="6">
                    <p className="text-right">
                      <Link
                        className="view-List"
                        to="/SuperAdmin/SuperNotification"
                      >
                        View All
                      </Link>
                    </p>
                  </Col>
                </Row>

                {notificationMes == null ? (
                  <div>No data found!</div>
                ) : (
                  notificationMes.map((item) => (
                    <>
                      <div className="notification">
                        <div className=" notification-right row">
                          <Col md="2" xs="6">
                            <div className="profile-pic2">
                              {itemData} {itemLastData}
                            </div>
                          </Col>
                          <Col md="10" xs="6">
                            <div className="ms-3">
                              <h1 className="notification-super">{item?.userName} </h1>
                              <div class="box">
                              <div className="notification-text-mess">
                              <p className="text-message-area">
                                {item?.message}  </p></div></div>
                              
                                <div className="mt-1 mb-2 text-right-date">
                                  {dayjs(item?.createDate).format("MM/DD/YYYY")}{" "}
                                  {dayjs(item?.createDate).format("HH:mm:ss")}
                                </div>
                            
                            </div>
                          </Col>


                        </div>
                      </div>
                    </>
                  ))
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  );
};
