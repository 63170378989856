import { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar"
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar"
import { Footer } from "../../Footer"
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { CheckoutAddPayment, CreatePymenentUrlFn, CustomerAddPayment, GetSubScription, PaymentGetById } from "../../redux/auth-reducer/auth-service";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export const Account_settings = () => {
    const [data, setData] = useState(false);
    const [dataItem, setDataItem] = useState([]);
    const [userData, setUserData] = useState();
    const navigate = useNavigate();
    const [dataSubscription,setDataSubscription] =useState();
    const sidebarfun = () => {
        setData(!data)
    }
    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser?.userId;
    const userName = isUser?.fullName;

    useEffect(() => {

        const GetByIdPayment = async () => {
            await PaymentGetById().then((response) => {
                setDataItem(response?.data?.data)
                console.log(response?.data?.data, "date time")
                setUserData(response);
            })
        }
        GetByIdPayment();

    }, []);


    useEffect(() => {

        const GetBySubscriptionFn = async () => {
            await GetSubScription().then((response) => {
                setDataSubscription(response?.data?.data)
                       // setDataItem(response?.data?.data)
                // console.log(response?.data?.data, "date time")
                // setUserData(response);
            })
        }
        GetBySubscriptionFn();

    }, []);

    console.log(dataSubscription,"dataSubscription")

    

    const planType = dataItem && dataItem.length > 0 ? (
        dataItem[0].planType === "Monthly" ? 1 :
            dataItem[0].planType === "yearly" ? 2 :
                null
    ) : null;

    // Log the computed planType
    console.log("Computed planType:", planType);

    const makePayment = async () => {
        await CreatePymenentUrlFn(planType).then((res) => {
            if (res?.status == 200) {
                let url = res?.data?.data[0]?.sessionUrl;
                window.open(url, "_blank")

            } else {

            }
        });
    }

    const convertUTCToLocalTime = (utcDateString) => {
        const date = new Date(utcDateString);
        return date.toLocaleString();
    };
    const localTime = convertUTCToLocalTime(dataItem[0]?.paymentDate + "Z");
    const [datePart, timePart] = localTime.split(", ");



    return (

        <>
            <TopNavbar sidebarfun={sidebarfun} />
            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Account Settings</h1>
                    <div className='group_data_table'>

                        <div className="subscription">
                            <h1 className="subscription_heading">About Subscription</h1>
                            <div className="subscription_box">
                                <div className="subscription_box-inner">
                                    <h4>Monthly Plan</h4>
                                    {userData === "No payment details found." ? "" : <Button variant="">Active</Button>}
                                </div>

                                <div className="subscription_box-inner pb-2 pt-2">
                                    {/* <p>Your next payment date is July 27, 2024</p> */}
                                </div>

                                <div className="subscription_box-inner">
                                    <p>Amount:</p>
                                    <p>{dataItem.length > 0 && (dataItem[0]?.paymentId == null || dataItem[0]?.paymentId) ? dataItem[0]?.amount : "0"}</p>
                                </div>

                                <div className="subscription_box-inner">
                                    <p>Auto Renew</p> <p>No</p>
                                </div>

                                <div className="subscription_box-inner">
                                    <p>Subscription Type</p>
                                    <p>{dataItem?.length > 0 && (dataItem[0]?.paymentId == null || dataItem[0]?.paymentId) ? dataItem[0]?.planType : "0"}</p>
                                </div>

                                <div className="subscription_box-inner">
                                    <p>Subscription created on Date</p>
                                    <p>{dataItem?.length > 0 && (dataItem[0]?.paymentId == null || dataItem[0]?.paymentId) ? dayjs(dataItem[0]?.paymentDate).format("MM/DD/YYYY") : "0"}</p>
                                </div>

                                <div className="subscription_box-inner">
                                    <p>Subscription created on Time</p>
                                    <p>{dataItem?.length > 0 && (dataItem[0]?.paymentId == null || dataItem[0]?.paymentId) ? timePart : "0"}</p>
                                </div>

                                <div className="subscription_box-inner">
                                    <p>Subscription End Date</p>
                                    <p>{dataSubscription?.length>0 &&(dataSubscription[0]?.subscriptionEndDate ==null ||dataSubscription[0].subscriptionEndDate? dayjs(dataSubscription[0]?.subscriptionEndDate).format("MM/DD/YYYY"):"0")}</p>
                                   
                                </div>
                                <div className="subscription_box-inner">
                                    <p>Subscription End Time</p>
                                    <p>{dataSubscription?.length > 0 && (dataSubscription[0]?.subscriptionEndDate == null || dataSubscription[0]?.subscriptionEndDate) ? timePart : "0"}</p>
                                   
                                </div>

                                <div className="subscription_box-inner">
                                    <p>Remaining Days:</p>
                                    <p>{dataSubscription?.length > 0 ? dataSubscription[0]?.subscriptionRemainingDays:"0"}</p>
                                </div>

                                <div className="subscription_box-inner-btn">
                                    {dataItem?.length > 0 && (dataItem[0]?.paymentId == null || dataItem[0]?.paymentId) && (
                                        <Button variant="" onClick={makePayment}>Make Payment</Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="table-transaction">
                            <h1 className="subscription_heading">Transaction History</h1>
                            <Table bordered hover className='table-team border'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Plan Type</th>
                                        {dataItem?.some(item => item?.paymentId) && <th>Transaction ID</th>}
                                        <th>Transaction Date</th>
                                        <th>Transaction Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataItem?.map((item) => {
                                            if (!item?.paymentId) return null;

                                            return (
                                                <tr key={item?.paymentId}>
                                                    <td>{item?.name}</td>
                                                    <td>
                                                        {item?.paymentStatus === "paid" ?
                                                            <p className="text-success">paid</p> :
                                                            <p className="text-danger">unpaid</p>}
                                                    </td>
                                                    <td>{item?.amount}</td>
                                                    <td>{item?.planType}</td>
                                                    <td>{item?.paymentId}</td>
                                                    <td>

                                                        {item?.name ? dayjs(item?.paymentDate).format("MM/DD/YYYY") : ""}
                                                    </td>
                                                    <td>



                                                        {item?.name ? timePart : ""}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </div>
                <div className={data ? "Footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}
