import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { SecodaryBidHistory, SecondaryMarketMyBidHistory } from '../../redux/auth-reducer/auth-service';
import { Footer } from "../../Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from 'react-bootstrap/Form';

export const SecodaryBid = () => {
    const [data, setData] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [apiDataMyBid, setApiDataMyBid] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchTextMyBid, setsearchTextMyBid] = useState('');

    const [count, setCount] = useState(0);
    function sidebarfun() {
        setSidebar(!sidebar)
    }
    useEffect(() => {
        dataApi()
    }, [count]);

    const dataApi = async (event) => {
        await SecodaryBidHistory().then((res) => {
            setApiData(res?.data?.data.dataList)
        })
    }

    useEffect(() => {

        const GetMyBids = async () => {
            await SecondaryMarketMyBidHistory().then((response) => {
                console.log(response?.data, "response");
                setApiDataMyBid(response?.data)

            })
        }
        GetMyBids();

    }, [])

    const columns = [
        {
            name: 'id',
            selector: (row, index) => index + 1,

            width: "80px"
        },
        {
            name: 'Team Name',
            selector: (row) => row?.teamName,
            sortable: true,

        },
        {
            name: 'Auction Name',
            selector: (row) => row?.auctionName,
            sortable: true,

        },

        {
            name: 'Bid Increment',
            selector: (row) => row?.bidIncrement,
            sortable: true,
        },
        {
            name: 'Total Placed Bids',
            selector: (row) => row?.totalPlacedBids,
            sortable: true,
        },

        {
            name: 'Bid Time',
            selector: (row) => row?.bidTime,
            sortable: true,
        },
        {
            name: 'Bid Amount',
            selector: (row) => row?.currentBidAmount,
            sortable: true,
        },
        {
            name: 'Ownership Sell',
            selector: (row) => row?.ownerShip,
            sortable: true,
        },

    ];

    const columnsMyBid = [
        {
            name: 'id',
            selector: (row, index) => index + 1,

            width: "80px"
        },
        {
            name: 'Team Name',
            selector: (row) => row?.teamName,
            sortable: true,

        },
        {
            name: 'Auction Name',
            selector: (row) => row?.auctionName,
            sortable: true,

        },

        {
            name: 'Bid Increment',
            selector: (row) => row?.bidIncrement,
            sortable: true,
        },
        {
            name: 'Level',
            selector: (row) => row?.auctionLevel,
            sortable: true,
        },

        {
            name: 'Bid Time',
            selector: (row) => row?.bidTime,
            sortable: true,
        },
        {
            name: 'Starting Bid',
            selector: (row) => row?.startingBid,
            sortable: true,
        },

        {
            name: 'Bid Amount',
            selector: (row) => row?.myBidAmount,
            sortable: true,
        },

    ];

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const handleSearchMyBid = (e) => {
        setsearchTextMyBid(e.target.value);
    };

    const filterDataAllBids = searchText ? apiData.filter((item) => {
        return item?.auctionName?.toLowerCase().includes(searchText?.toLowerCase())
            || item?.teamName?.toLowerCase().includes(searchText?.toLowerCase())

    }) : apiData;

    const filterDataMyBids = searchTextMyBid ? apiDataMyBid.filter((item) => {
        return item?.auctionName?.toLowerCase().includes(searchTextMyBid?.toLowerCase())
            || item?.teamName?.toLowerCase().includes(searchTextMyBid?.toLowerCase())

    }) : apiDataMyBid;

    return (

        <>

            <TopNavbar sidebarfun={sidebarfun} />
            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Secondary Market</h1>
                    <div className='group_data_table'>

                        <div className="group_data_table tabs-auction">
                            <div className="tabs-box">
                                <Tabs
                                    defaultActiveKey="All Bids"
                                    id="auctiontabs"
                                    // onSelect={handleTabChange}
                                    className="mb-0 tab-list-item"
                                    justify
                                >
                                    <Tab eventKey="All Bids" title="All Bids">
                                        <div>
                                            <div className="search-input filter auction-filter palyer">

                                                <Form.Group as={Col} md="6 mt-3 me-3" className="text-left  mt-2" controlId="validationCustomOption">
                                                    <Form.Label className="search-player-auction">Search</Form.Label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3 mt-3"
                                                        placeholder="Search..."
                                                        value={searchText} onChange={handleSearch}
                                                    />
                                                </Form.Group>
                                            </div>
                                      
                                        <DataTable
                                            title="dfsfd"
                                            columns={columns}
                                            data={filterDataAllBids}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            paginationTotalRows={filterDataAllBids?.length}
                                        />
                                    </div>
                                    </Tab>

                                    <Tab eventKey="My Bids" title="My Bids">
                                        <div>

                                            <div className="search-input filter auction-filter palyer">

                                                <Form.Group as={Col} md="6 mt-3 me-3" className="text-left  mt-2" controlId="validationCustomOption">
                                                    <Form.Label className="search-player-auction">Search</Form.Label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3 mt-3"
                                                        placeholder="Search..."
                                                        value={searchTextMyBid} onChange={handleSearchMyBid}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <DataTable
                                                columns={columnsMyBid}
                                                data={filterDataMyBids}
                                                pagination
                                                paginationPerPage={10}
                                                defaultSortFieldId={1}
                                                paginationRowsPerPageOptions={[10, 20, 30]}
                                            />

                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={data ? "Footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}


