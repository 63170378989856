import React, { useEffect, useState } from 'react'
import generator from "tournament-generator";
import _ from "lodash";
import { Bracket, Seed, SeedItem, SeedTeam } from "react-brackets";
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { Footer } from '../../Footer';
import { useLocation, useParams } from 'react-router-dom';
import { BracketUpdate, TeamAll, TeamGetAll, GetTournamentTeamMatch, TournamentUpdatePlayerMatchDate, TournamentUpdatePlayerMatchScore, TournamentBarcketId, TournamentUpdateTeamDate, TournamentUpdateTeamScore } from '../../redux/auth-reducer/auth-service';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move'
import { setSeconds } from 'rsuite/esm/utils/dateUtils';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from 'react-bootstrap/Modal';
import * as icons from 'react-bootstrap-icons';
import { Button, Card, Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'
import { DatePicker } from 'rsuite';
import { ToastContainer, toast } from 'react-toastify';
import dayjs from 'dayjs';
export const SetupBracket = (props) => {

  const [data, setData] = useState(false);
  const [roundData, setRoundData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editModeByIndex, setEditModeByIndex] = useState("");
  const [secondsId, setSecondsId] = useState();
  const [scondMtachId, setScondMtachId] = useState();
  const [matchIdScore, setMatchIdScore] = useState([]);
  const [tournamentIdScore, setTournamentIdScore] = useState()
  const [firstMatchIdItem, setFirstMatchIdItem] = useState();
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [noBracketshow, setNoBracketshow] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const [validated, setValidated] = useState(false);
  const [dataList, setDataList] = useState({
    leftTeamScore: 0,
    rightTeamScore: 0,
  });
  const [scoreData, setScoreData] = useState([])
  const [teamData, setTeamData] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedNoBracket, SetIsCheckedNoBracket] = useState(false);
  const handleClose = () => setShow(false);
  const handleNoBracketClose = () => setNoBracketshow(false);
  const [startMatch, setStartMatch] = useState()
  const [startNoBracketMatch, setStartNoBracketMatch] = useState(null)
  const [scoreNoBracket, setScoreNoBracket] = useState("")
  const [dateValid, setDateValid] = useState(true);
  const handleShow = (list) => {

    setDataList(list)
    // setCheckList(true);
    setShow(true);

  }
  console.log(dataList, "dataList")
  const [dateUpdate, setDateUpdate] = useState();
  const [scoreUpdate, setScoreUpdate] = useState({
    matchId: 0,
    tournamentId: 0,
    leftTeamScore: 0,
    rightTeamScore: 0,
    isCompleted: true
  })
  const { id } = useParams();

  function sidebarfun() {
    setData(!data)
  }

  useEffect(() => {
    Bracketfn()
  }, []);

  useEffect(() => {
    if (dataList?.matchStartDate) {
      const startDate = new Date(dataList?.matchStartDate);
      const timezoneOffset = startDate.getTimezoneOffset();
      startDate.setMinutes(startDate.getMinutes() - timezoneOffset);
      if (dataList?.matchStartDate === "0001-01-01T00:00:00" || dataList?.matchStartDate === null || dataList?.matchStartDate === "") {
        const dateItem = new Date();
        setStartMatch(dateItem);
      } else {
        setStartMatch(startDate);
      }

    }
  }, [dataList]);



  const Bracketfn = async () => {
    await TournamentBarcketId(id).then((res) => {
      setRoundData(res)
    })
  }

  useEffect(() => {
    const BracketfnGet = async () => {
      await TournamentBarcketId(id).then((res) => {
        console.log(res, "res>>>>>>>>>>>")
        setScoreData(res)
      })
    }
    BracketfnGet()
  }, [count]);

  useEffect(() => {
    roundData?.map((item) => {
      // {item?.tournamentId};
      setTournamentIdScore(item?.tournamentId);
      console.log(item?.matchId, "dsfdf")
      setMatchIdScore(item?.matchId)

    })
  })

  const handleEditClickTeam = (ind, id) => {
    console.log(ind, id, "??????")
    setFirstMatchIdItem(id)
    setEditModeByIndex(ind);
    setEditMode(true);
  }
  const handelOnChange = (e) => {
    const [value, array] = e.target.value.split(",");
    setSecondsId(array);
    setScondMtachId(value);
    console.log(value, "value");
    console.log(array, "array");
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleNoBracketCheckboxChange = (event) => {
    SetIsCheckedNoBracket(event.target.checked);
  };

  const handelDate = (e) => {
    console.log(e, "dfdf");
    // setDateUpdate(e)
    setStartMatch(e)

  }

//   const handelNoBracketDate = (e) => {

// console.log("dfdf")
//     setDateUpdate(e)
//     // setStartNoBracketMatch(e)


//   }

const handelNoBracketDate = (e) => {
  console.log("Date changed:", e);
  setDateValid(true);
  setStartNoBracketMatch(e); // Update the state with the new date
  setDateUpdate(e); // Assuming this is for another state update

}
console.log(dateUpdate,"dateUpdate")

  const handleDateUpdate = async () => {
    const data =
    {
      "matchId": dataList?.matchId,
      "tournamentId": tournamentIdScore,
      "matchStartDate": startMatch
    }
    await TournamentUpdateTeamDate(data).then((res) => {
      if (res?.status == true) {
        console.log(res?.message, "res");
        toast.success(res?.message);
        setCount(count + 1);
        setShow(false);
      }
      else {
        toast.error(res?.message);
      }
    })
  }

  const handleNoBracketDateUpdate = async (tournamentId) => {

    if (!dataList?.matchStartDate) {
      setDateValid(false);
      setValidated(true);
      return;
  } else {
      setDateValid(true);
     
  }
    const data =
    {
      "tournamentId": tournamentId,
      "matchStartDate": startNoBracketMatch
    }
    await TournamentUpdatePlayerMatchDate(data).then((res) => {
      if (res?.data?.status === true) {
        toast.success(res?.data?.message);
        setDateValid(true);
        setCount(count + 1);
        setNoBracketshow(false)
      }
      else {
        toast.error(res?.data?.message);
      }
    })
  }
  

  const handleScorecheck = async () => {
    const data = {
      "matchId": dataList?.matchId,
      "tournamentId": tournamentIdScore,
      "leftTeamScore": Number(dataList?.leftTeamScore),
      "rightTeamScore": Number(dataList?.rightTeamScore),
      "isCompleted": isChecked
    };
    console.log(data, "data");

    await TournamentUpdateTeamScore(data).then(async (res) => {
      if (res?.status === true) {
        console.log(res?.message, "res");
        toast.success(res?.message);
        setCount(count + 1);
        setShow(false);
        // Fetch updated score data after successful update
      } else {
        toast.error(res?.message);
      }
    });
  };
  const closebtn = () => {
    setEditMode(false);
  }

  const handleNoBracketScorecheck = async (tournamentId, matchId) => {
    setValidated(true)

    const data = {
      "matchId": selectedListItem.matchId,
      "tournamentId": tournamentId,
      "playerScore": scoreNoBracket,
      "isCompleted": isChecked
    };
 

    await TournamentUpdatePlayerMatchScore(data).then(async (res) => {
      console.log(res?.data, "res");
      if (res?.data?.status === true) {
        toast.success(res?.data?.message);
        setCount(count + 1);
        setNoBracketshow(false);
        // Fetch updated score data after successful update
      } else {
        toast.error(res?.data?.message);
      }
    });
  };

  useEffect(() => {
    const fetchTournamentData = async () => {
      if (roundData.length === 0) {
        const res = await GetTournamentTeamMatch(id);
        setTeamData(res?.data)
      }
    };

    fetchTournamentData();
  }, [count]);

  console.log(teamData, "dfafd")

  const handelScoreUpdate = (e) => {
    setDataList({
      ...dataList,
      [e.target.name]: e.target.value
    })
  }

  const handelNoBracketScoreUpdate = (e) => {
    const newScore = e.target.value;
    console.log(newScore, "e");
    setScoreNoBracket(newScore);
  };

  var roundDataTitle = []
  var roundDataLeftTeam = []
  roundData?.map((item) => {
    roundDataTitle.push(item?.matchLevel);
  })
  roundDataTitle = [...new Set(roundDataTitle)].sort((b, a) => a - b);
  let round = roundDataTitle?.map((value, index) => {
    let dataItem = {
      title:
        <>

        </>,
      seeds: [],
    }
    let count = 0;
    const convertUTCToLocalTime = (utcDateString) => {
      const date = new Date(utcDateString); // Create a Date object from the UTC string
      return date.toLocaleString(); // Convert to local time string
    };


    roundData?.map((item, ind) => {
      const handleSaveClick = async () => {

        const Updatedata =
        {
          "tournamentId": Number(id),
          "firstTeamMatchId": firstMatchIdItem,
          "firstTeamId": editModeByIndex,
          "secondTeamMatchId": Number(scondMtachId),
          "secondTeamId": Number(secondsId),
        }
        await BracketUpdate(Updatedata).then((res) => {
          if (res?.status == true) {
            Bracketfn();
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
        })
        setEditMode(false);
      };

      if (value == item.matchLevel) {
        dataItem.seeds.push({

          id: item.matchLevel,
          date: `[${new Date().toISOString()}]`,
          teams: item.rightTeamId > 0 ? [{
            name: <>
              <button className='saveScoreBtn' onClick={() => handleShow(item)}>Score</button>
              <span className='back-count'>
                <p>{scoreData?.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore !== null ? scoreData.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore : 0}</p>
              </span>
             
              {/* <p>{item?.leftTeamName?==null?"":""}</p> */}
              {editMode && editModeByIndex == item.leftTeamId ? (
                <select onChange={handelOnChange} >
                  <option>Select</option>
                  {roundData?.map((itemList, i) => {
                    return (
                      <>
                        <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
                        <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
                      </>
                    )
                  })}
                </select>
              ) : (
                <>
                  <span>{item?.leftTeamName}</span>
                </>
              )
              }

              {editMode && editModeByIndex == item.leftTeamId ? (
                <>
                  <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /></button>
                  <button ><icons.XLg onClick={closebtn} /></button>
                </>
              ) : (
                <button onClick={() => handleEditClickTeam(item?.leftTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /></button>
              )}

            </>
          },
          {
            name: <>

              <span className='back-count'>
                <p>{scoreData?.find(data => data.rightTeamId === item.rightTeamId)?.rightTeamScore !== null ? scoreData.find(data => data.rightTeamId === item.rightTeamId)?.rightTeamScore : 0}</p>
              </span>

              {editMode && editModeByIndex == item.rightTeamId ? (
                <select onChange={handelOnChange}>
                  <option>Select</option>
                  {roundData?.map((itemList, i) => {
                    return (
                      <>
                        <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
                        <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
                      </>
                    )
                  })}
                </select>
              ) : (
                <>
                  <span>{item?.rightTeamName}</span>

                  {item?.matchStartDate === "0001-01-01T00:00:00" ? null : (() => {
                    const localTime = convertUTCToLocalTime(item?.matchStartDate + "Z");
                    const [datePart, timePart] = localTime.split(", ");

                    return (
                      <p className='date-match'>
                        {dayjs(item?.matchStartDate).format("MM/DD/YYYY")}  {timePart}
                      </p>
                    );
                  })()}
                </>
              )

              }

              {

                editMode && editModeByIndex == item.rightTeamId ? (<>
                  <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /> </button>
                  <button ><icons.XLg onClick={closebtn} /></button></>
                ) : (
                  <button onClick={() => handleEditClickTeam(item.rightTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /> </button>
                )

              }

            </>
          }

          ]

            : 

            [{
              name: <>
              {
                item?.leftTeamName == null && item.rightTeamName == null? "" :<button className='saveScoreBtn' onClick={() => handleShow(item)}>Score</button>
              }

              {
                item?.leftTeamName == null && item.rightTeamName == null? "" : 
                 <span className='back-count'>
                <p>{scoreData?.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore !== null ? scoreData.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore : 0}</p>
              </span>
              }
              

           
                {/* <button className='saveScoreBtn' onClick={() => handleShow(item)}>Score</button> */}
                {editMode && editModeByIndex == item.leftTeamId ? (
                     
                  <select onChange={handelOnChange} >
                    <option>Select</option>
                    {roundData?.map((itemList, i) => {
                      return (
                        <>
                          <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
                          <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
                        </>
                      )
                    })}
                  </select>
                
                ) : (
                  <>
                    <span>{item?.leftTeamName}</span>
                  </>
                )
                }
  
                {editMode && editModeByIndex == item.leftTeamId ? (
                  <>
                    <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /></button>
                    <button ><icons.XLg onClick={closebtn} /></button>
                  </>
                ) : (
                  <>
                  {item?.leftTeamName==null? <p className='p-2'>No Team</p>:<button onClick={() => handleEditClickTeam(item?.leftTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /></button>}
                </>
                  // <button onClick={() => handleEditClickTeam(item?.leftTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /></button>
                )}
  
              </>
            },
            {
              name: <>
     {
                item?.leftTeamName == null && item.rightTeamName == null? "" : 
                 <span className='back-count'>
                <p>{scoreData?.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore !== null ? scoreData.find(data => data.leftTeamId === item.leftTeamId)?.leftTeamScore : 0}</p>
              </span>
              }
              
                {/* <span className='back-count'>
                  <p>{scoreData?.find(data => data.rightTeamId === item.rightTeamId)?.rightTeamScore !== null ? scoreData.find(data => data.rightTeamId === item.rightTeamId)?.rightTeamScore : 0}</p>
                </span> */}
  
                {editMode && editModeByIndex == item.rightTeamId ? (
                  <select onChange={handelOnChange}>
                    <option>Select</option>
                    {roundData?.map((itemList, i) => {
                      return (
                        <>
                          <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
                          <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
                        </>
                      )
                    })}
                  </select>
                ) : (
                  <>
                    <span>{item?.rightTeamName} </span>
  
                    {item?.matchStartDate === "0001-01-01T00:00:00" ? null : (() => {
                      const localTime = convertUTCToLocalTime(item?.matchStartDate + "Z");
                      const [datePart, timePart] = localTime.split(", ");
  
                      return (
                        <p className='date-match'>
                          {dayjs(item?.matchStartDate).format("MM/DD/YYYY")}  {timePart}
                        </p>
                      );
                    })()
                    }
                  </>
                )
  
                }
  
                {
  
                  editMode && editModeByIndex == item.rightTeamId ? (<>
                    <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /> </button>
                    <button ><icons.XLg onClick={closebtn} /></button></>
                  ) : (
                    <>
                    {item?.leftTeamName==null?  <p className='p-2'>No Team</p>:<button onClick={() => handleEditClickTeam(item.rightTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /> </button>}
                  </>
                    // <button onClick={() => handleEditClickTeam(item.rightTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /> </button>
                  )
  
                }
  
              </>
            }
  
            ]


            // [{
            //   name: <>
            //     <span className='me-3'>
              
            //       {editMode ? (
            //         <select onChange={handelOnChange}>
            //           <option>Select</option>
            //           {roundData?.map((itemList, i) => {
            //             return (
            //               <>
            //                 <option value={`${itemList?.matchId} , ${itemList.leftTeamId}`} >{itemList?.leftTeamName}</option>
            //                 <option value={`${itemList?.matchId} , ${itemList.rightTeamId}`}>{itemList?.rightTeamName}</option>
            //               </>
            //             )
            //           })}
            //         </select>
            //       ) : (
              
            //         <span>{item?.leftTeamName}</span>
            //       )}
            //       {editMode ? (
            //         <>
            //           <button onClick={handleSaveClick}><icons.Save2 className='save-icon' /></button>
            //           <button ><icons.XLg onClick={closebtn} /></button>
            //           </>
                  
            //       ) : (
                   
            //         <button onClick={() => handleEditClickTeam(item.rightTeamId, item?.matchId)}><icons.Shuffle className='suffle-icon' /></button>
            //       )}
            //     </span> </>
            // }],
        });
        if (item.rightTeamId > 0) {
          count += 2;
        } else {
          count += 1;
        }
      }
    });
    return dataItem
  });

  const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }) => {
    return (
      <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem>
          <div>
            <SeedTeam>{seed.teams[0]?.name || 'NO TEAM '}</SeedTeam>
            {seed.teams[1]?.name && <SeedTeam>{seed.teams[1]?.name || 'NO TEAM '}</SeedTeam>}
          </div>
        </SeedItem>
      </Seed>
    );
  };

  const scoreNoBracketModel = (listItem) => {
    setSelectedListItem(listItem)
/************** */

const dateCurrent =dayjs(listItem?.matchStartDate).format("MM/DD/YYYY") === "01/01/1901" 
  ? dayjs().format("MM/DD/YYYY") 
  : dayjs(listItem?.matchStartDate).format("MM/DD/YYYY");
    setStartNoBracketMatch(dateCurrent);


    setScoreNoBracket(listItem.playerScore)
    setNoBracketshow(true);
    console.log(listItem, "listItem");
};


  return (
    <>
      <ToastContainer />
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <div class="bracket  disable-image bg-white ">

            {
              roundData.length === 0 ? (<>

                <Card style={{ width: '25rem' }} className='p-3'>
                  <h4>Player List </h4>
                  <ListGroup variant="flush" class="list-item-player">
                    {teamData?.map((listItem) => <ListGroup.Item>
                      <div className='score-player-name'>
                      
                      <h5> <span className='text-score-update'>{listItem.playerScore}  </span> {listItem?.playerName}  </h5>
                      </div>
                    
                      <div className='score-date-edit'>
                      <Button variant='success' className='ms-3' onClick={() => scoreNoBracketModel(listItem)} >Edit</Button>
                      </div>
                    
                      <div className='dateMatach mt-2'> 
                      {dayjs(listItem?.matchStartDate).format("MM/DD/YYYY") === "01/01/1901" 
                      ? dayjs().format("MM/DD/YYYY") 
                      : dayjs(listItem?.matchStartDate).format("MM/DD/YYYY")}
                     
                      
                       </div>


                      <Modal show={noBracketshow} onHide={handleNoBracketClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Update Scores / Date  </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                          <Tabs
                            defaultActiveKey="Player Score"
                            id="auctiontabs1"
                            className="mb-3 tab-list-item"
                            justify
                          >
                            <Tab eventKey="Player Score" title="Score">
                         
                              {/* <Form.Group className="mb-3" >
                             
                                  <Form.Control
                                    required
                                    type="number"
                                    name="rightTeamScore"
                                    value={scoreNoBracket}
                                    maxLength={50}
                                    onChange={handelNoBracketScoreUpdate}
                                  />
                               

                              </Form.Group> */}
                          
                              {/* <Form.Control  
                                    type="number"
                                    name="rightTeamScore"
                                    value={scoreNoBracket}
                                    maxLength={50}
                                    onChange={handelNoBracketScoreUpdate} required ></Form.Control>

                               <Form.Control.Feedback type="invalid">
                                    Please enter score.
                                </Form.Control.Feedback> */}
                                <Form validated={validated}>

                           
                        <Form.Group controlId="validationCustom01">
                            <Form.Control
                                required
                                type="number"
                                maxLength={50}
                                name="rightTeamScore"
                                value={scoreNoBracket}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    // Check if the value is a valid number and not negative
                                    if (value >= 0 || value === '') {
                                        handelNoBracketScoreUpdate(e);
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a score.
                            </Form.Control.Feedback>
                        </Form.Group>
                              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" checked={isCheckedNoBracket}
                                  onChange={handleNoBracketCheckboxChange} label="Match Completed" />
                              </Form.Group>


                              <Button variant="outline-secondary" className='me-3' onClick={handleNoBracketClose}>
                                Cancel
                              </Button>
                              <Button variant="success" onClick={() => handleNoBracketScorecheck(listItem.tournamentId)}>
                                Submit
                              </Button>
                              </Form>
                            </Tab>
                            <Tab eventKey="Player Date" title="Date">

                            <Form validated={validated}>
                              <Form.Group as={Row} className="mb-5" >
                                
                                <DatePicker
                                  placeholder="Select Date"
                                  format="MM/dd/yyyy hh:mm aa"
                                   disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)}
                                  onChange={handelNoBracketDate}
                                  name="matchStartDate"
                                  className='Auction-Date'
                                  required
                                />

                              <Form.Control.Feedback type="invalid" style={{ display: dateValid ? 'none' : 'block' }}>
                                    Please select the match start date and time.
                                </Form.Control.Feedback>
                            
                               

                                {/* <Form.Control.Feedback type="invalid">
                                    Please select start date.
                                </Form.Control.Feedback> */}
                                     
                            </Form.Group>

                              <Button variant="success" onClick={() => handleNoBracketDateUpdate(listItem.tournamentId)}>
                                Submit
                              </Button>
                              </Form>
                            </Tab>

                          </Tabs>

                        </Modal.Body>
                     
                      </Modal>

                    </ListGroup.Item>)}

                  </ListGroup>
                </Card>


              </>)
                :
                (<>
                  <Bracket rounds={round} renderSeedComponent={CustomSeed} />
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Update Scores / Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      <Tabs
                        defaultActiveKey="Score"
                        id="auctiontabs"
                        className="mb-3 tab-list-item"
                        justify
                      >
                        <Tab eventKey="Score" title="Score">
                          <Form.Group as={Row} className="" >
                            <Form.Label column sm="2" className='p-0'>

                              <Form.Control
                                required
                                type="number"
                                name="leftTeamScore"
                                value={dataList?.leftTeamScore == null ? 0 : dataList?.leftTeamScore}
                                maxLength={50}
                                onChange={handelScoreUpdate}

                              />
                            </Form.Label>
                            <Col sm="10">
                              <Form.Control type="text" value={dataList?.leftTeamName} />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} className="" >
                            <Form.Label column sm="2" className='p-0'>
                              <Form.Control
                                required
                                type="number"
                                name="rightTeamScore"
                                value={dataList?.rightTeamScore == null ? 0 : dataList?.rightTeamScore}
                                maxLength={50}
                                onChange={handelScoreUpdate}

                              />
                            </Form.Label>
                            <Col sm="10">
                              <Form.Control type="text" value={dataList?.rightTeamName} />
                            </Col>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" checked={isChecked}
                              onChange={handleCheckboxChange} label="Match Completed" />
                          </Form.Group>

                          {/* <p>By Proceeding, you will not be able to update scores again.</p> */}

                          <Button variant="outline-secondary" className='me-3' onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button variant="success" onClick={handleScorecheck}>
                            Submit
                          </Button>
                        </Tab>
                        <Tab eventKey="profile" title="Date">
                          <Form.Group as={Row} className="mb-3" >

                            <DatePicker placeholder="Select Date" format="MM/dd/yyyy hh:mm aa" value={startMatch} disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)} onChange={handelDate} name="matchStartDate" className='Auction-Date' />

                          </Form.Group>
                          <Button variant="success" onClick={handleDateUpdate}>
                            Submit
                          </Button>
                        </Tab>

                      </Tabs>

                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                  </Modal>
                </>)
            }

          </div >

        </div >
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div >
    </>

  );
}
