import { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Card, Col, Form, Row, Tabs } from "react-bootstrap";
import { Footer } from "../../Footer";
import DoughnutChart from "../Outer/DoughnutChart";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { GetRefreshToken, GetToken, RemoveRefreshToken } from "../../utils/helper/helper";
import { Link, useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { GetInvoiceDetails, GetNotificationMes, OragnizationGetRecent, OragnizationGetTotal, SubscriptionDataFilterByDate, UserGetTotal, GetSubscriptionCount } from "../../redux/auth-reducer/auth-service";
import Tab from 'react-bootstrap/Tab';
import dayjs from "dayjs";
import { DatePicker } from "rsuite";

function Dashboard() {
    const [data, setData] = useState(false);
    const navigate = useNavigate();
    const [apiData, setApiData] = useState([]);
    const [totalOrgData, setTotalOrgData] = useState([]);
    const [totalUserData, setTotalUserData] = useState([]);
    const [notificationMes, setNotificationMes] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [itemLastData, setItemLastData] = useState([]);
    const [apiDataInvoice, setApiDataInvoice] = useState([]);
    const [startAuctionDate, setStartAuctionDate] = useState("01/01/2024 ");
    // const [startAuctionDate, setStartAuctionDate] = useState();
    const [endAuctionDate, setEndAuctionDate] = useState(dayjs(new Date()).format("MM/DD/YYYY"));
    const [dataSubscription, setDataSubscription] = useState();
    const [subscriptionCount, setSubscriptionCount] = useState();


    useEffect(() => {
        const isAuth = localStorage.getItem('role');
        if (isAuth == 'Web Admin') {

        } else {
            navigate("/Login")
        }

    }, [])
    useEffect(() => {
        let Token = GetToken()
        if (!Token) {
            navigate('/Login')
        }

    }, [])
    function sidebarfun() {
        setData(!data)
    }
    useEffect(() => {
        const GetRecentData = async () => {
            await OragnizationGetRecent().then((res) => {
                setApiData(res?.data)
            })
        }
        GetRecentData()
    },
        []);

    useEffect(() => {
        const GetRecentDataInvoice = async () => {
            await GetInvoiceDetails().then((res) => {
                setApiDataInvoice(res?.data?.data?.dataList);
                console.log(res, "Invoice?????????")

            })
        }
        GetRecentDataInvoice()
    },
        []);

    useEffect(() => {
        const GetTotalData = async () => {
            await OragnizationGetTotal().then((res) => {
                setTotalOrgData(res?.data)

            })
        }
        GetTotalData()
    },
        []);

    useEffect(() => {
        const GetTotalUser = async () => {
            await UserGetTotal().then((res) => {
                setTotalUserData(res?.data)

            })
        }
        GetTotalUser()
    },
        [])

    useEffect(() => {
        const NotificationMessageFun = async () => {
            await GetNotificationMes().then((response) => {
                setNotificationMes(response?.data?.dataList);
            })
        }
        NotificationMessageFun();
    }, [])

    useEffect(() => {

        notificationMes?.map((item) => {
            var user = item?.userName.split(" ");
            var dataList = user?.[0];
            var dataList2 = user?.[1];
            var listItem = dataList.charAt(0)
            var listItem2 = dataList2.charAt(0)
            setItemData(listItem)
            setItemLastData(listItem2)
        })

    }, [notificationMes])
    /************************** */
    const handelStartDate = async (event) => {
        setStartAuctionDate(dayjs(event).format("MM/DD/YYYY"))
    };
    const handelEndDate = async (event) => {
        setEndAuctionDate(dayjs(event).format("MM/DD/YYYY"))
    };
    useEffect(() => {
        if (startAuctionDate && endAuctionDate) {

            const AuctionFilter = async () => {
                await SubscriptionDataFilterByDate(startAuctionDate, endAuctionDate).then((response) => {
                    console.log(response?.data[0], "count");
                    setDataSubscription(response?.data[0]);

                });
            };
            AuctionFilter();

            const SubscriptionRevnueCount = async () => {
                await GetSubscriptionCount(startAuctionDate, endAuctionDate).then((response) => {

                    setSubscriptionCount(response?.data)

                });
            };
            SubscriptionRevnueCount();

        }
    }, [startAuctionDate, endAuctionDate])



    return (
        <>

            <ToastContainer></ToastContainer>
            <TopNavbar sidebarfun={sidebarfun} />
            <div className="dasboard-group" >
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Dashboard</h1>
                    <Row>
                        <Col >
                            <div className="sec-item">

                                <div className="item-sec">
                                    <h4><b>Registered Managers</b></h4>
                                    <Row>

                                        <Col>

                                            <div className="group-content">

                                                <div className="item-1">
                                                    <img src="../image/oragnization.svg" alt="Organization" width={35}></img>
                                                </div>
                                                <div className="content ms-3">
                                                    <h2>{totalOrgData?.activeCount}</h2>
                                                    <p className="text-success">Active</p>
                                                </div>
                                            </div>
                                            <div class="vl"></div>
                                        </Col>
                                        <Col>

                                            <div className="content">
                                                <h2>{totalOrgData?.expiredCount}</h2>
                                                <p className="text-danger">Expired</p>

                                            </div>

                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col >
                            <div className="sec-item">

                                <div className="item-sec">
                                    <h4><b>Registered Players</b></h4>
                                    <Row>

                                        <Col>

                                            <div className="group-content">

                                                <div className="item-2">
                                                    <img src="../image/user.svg" alt="user" width={35}></img>
                                                </div>
                                                <div className="content ms-3">
                                                    <h2>{totalUserData?.activeCount}</h2>
                                                    <p className="text-success">Active</p>
                                                </div>
                                            </div>
                                            <div class="vl"></div>
                                        </Col>
                                        <Col>

                                            <div className="content">
                                                <h2>{totalUserData?.expiredCount}</h2>
                                                <p className="text-danger">Expired</p>

                                            </div>

                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </Col>

                    </Row>

                    <div className="group-card">

                    </div>

                    <Row>
                        <Col md="6"  >
                            <Card className="orgnization-sec Recent-manager" style={{ height: "350px" }}>
                                <Row>
                                    <Col md="6" xs="6">   <h2>Recent Added Managers</h2>

                                    </Col>
                                    <Col md="6" xs="6"><p className="text-right"> <Link to="/WebAdmin/ViewOrganization" className='view-List'>View All</Link></p></Col>
                                    <Col md="12 orgnization-table">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Frist Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email</th>
                                                    <th>Company Name</th>


                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    apiData?.map((item, index) =>

                                                        <tr>
                                                            <td scope='col'>{item.firstName}</td>
                                                            <td scope='col'>{item.lastName}</td>
                                                            <td scope='col'>{item.emailId}</td>
                                                            <td scope='col'>{item.orgName}</td>


                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                        <Col md="6" className="revenue-sec-mob" >
                            <Card className="revenue-sec">
                                <h2>Subscription Revenue</h2>

                                <Tabs
                                    defaultActiveKey="profile"
                                    id="justify-tab-example"
                                    className="mb-3"
                                    justify
                                >

                                    <Tab eventKey="profile" title="Amount">



                                        <Row>
                                            <Col md={5}>


                                                <div className="group-sec">

                                                    <DoughnutChart monthly={dataSubscription?.monthlySubscriptionRevenue} yearly={dataSubscription?.yearlySubscriptionRevenue} today={dataSubscription?.dailySubscriptionRevenue} />

                                                    <div className="total amount">
                                                        Total
                                                        <div className="amount-text">
                                                            {dataSubscription?.totalSubscriptionRevenue}
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md={7}>
                                                <div>

                                                    <Row>
                                                        <Col>
                                                            {/* <Form.Group>
                                                                <Form.Label>From</Form.Label>
                                                                <DatePicker placeholder="Start Date"   format="MM/dd/yyyy hh:mm aa" name="startDate" className=' Auction-Date' onChange={handelStartDate} required defaultValue={new Date('2024-01-01')} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter Auction Start Date.
                                                                </Form.Control.Feedback>
                                                            </Form.Group> */}

                                                            <Form.Group>
                                                                <Form.Label>From</Form.Label>
                                                                <DatePicker
                                                                    placeholder="Start Date"
                                                                    format="MM/dd/yyyy hh:mm aa"
                                                                    name="startDate"
                                                                    className='Auction-Date'
                                                                    onChange={handelStartDate}
                                                                    required
                                                                    defaultValue={new Date('2024-01-01T00:00:00')} // Set to midnight UTC
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter Auction Start Date.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>To</Form.Label>
                                                                <DatePicker placeholder="End Date" format="MM/dd/yyyy hh:mm aa" name="endDate" className=' Auction-Date' onChange={handelEndDate} required defaultValue={new Date()} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter Auction End Date.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <h6 className="mt-3"><b>Amount</b></h6>

                                                    <>
                                                        <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle me-2'></div> <p className='text-color-tab'>Monthly:{dataSubscription?.monthlySubscriptionRevenue}</p></div></Col>
                                                        <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle-pro  me-2'></div><p className='text-color-tab'>Today: {dataSubscription?.dailySubscriptionRevenue}</p></div></Col>
                                                        <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle-up  me-2'></div><p className='text-color-tab'> Yearly: {dataSubscription?.yearlySubscriptionRevenue}</p></div></Col>

                                                    </>


                                                </div>
                                            </Col>
                                        </Row>

                                    </Tab>





                                    <Tab eventKey="Amount" title="Count">

                                        <Row>
                                            <Col md={6}>

                                                <div className="group-sec">

                                                    <DoughnutChart monthly={subscriptionCount?.monthlySubscriptionCount} yearly={subscriptionCount?.yearlySubscriptionCount} today={subscriptionCount?.dailySubscriptionCount} />

                                                    <div className="total Count">
                                                        Total
                                                        <div className="Count-text">
                                                            {subscriptionCount?.totalSubscriptionCount}
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div >

                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>From-</Form.Label>
                                                                <DatePicker placeholder="Start Date" format="MM/dd/yyyy hh:mm aa" name="startDate" className=' Auction-Date' onChange={handelStartDate} required defaultValue={new Date('2024-01-01')} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter Auction Start Date.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>To-</Form.Label>
                                                                <DatePicker placeholder="End Date" format="MM/dd/yyyy" name="endDate" className=' Auction-Date' onChange={handelEndDate} required defaultValue={new Date()} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter Auction End Date.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <h6 className="mt-3"><b>Count</b></h6>

                                                    <>
                                                        <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle me-2'></div> <p className='text-color-tab'>Monthly:{subscriptionCount?.monthlySubscriptionCount}</p></div></Col>
                                                        <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle-pro  me-2'></div><p className='text-color-tab'>Today: {subscriptionCount?.dailySubscriptionCount}</p></div></Col>
                                                        <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle-up  me-2'></div><p className='text-color-tab'> Yearly: {subscriptionCount?.yearlySubscriptionCount}</p></div></Col>

                                                    </>

                                                </div>
                                            </Col>
                                        </Row>





                                        {/* <div >
                                                        <h6><b>Count</b></h6>

                                                        <>
                                                            <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle me-2'></div> <p className='text-color-tab'>Monthly:{subscriptionCount?.monthlySubscriptionCount}</p></div></Col>
                                                            <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle-pro  me-2'></div><p className='text-color-tab'>Today: {subscriptionCount?.dailySubscriptionCount}</p></div></Col>
                                                            <Col className='mt-3'><div className='d-flex mb-3'><div className='list-circle-up  me-2'></div><p className='text-color-tab'> Yearly: {subscriptionCount?.yearlySubscriptionCount}</p></div></Col>

                                                        </>

                                                    </div> */}
                                    </Tab>



                                </Tabs>







                                {/* <Row>
                                    <Col md={8} >
                                        
                                    </Col>
                                    <Col md={4}>
                                        <div >
                                            <div className="vl"></div>
                                           

                                        </div>
                                    </Col>
                                </Row> */}

                            </Card>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="6" >
                            <Card className="orgnization-sec Invoice">
                                <Row>
                                    <Col md="6" xs="6">    <h2>Recent Invoice List</h2>

                                    </Col>

                                </Row>
                                <div className=" bid-table">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Invoice no.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Address</th>
                                                <th>Plan Type </th>
                                                <th>Amount</th>
                                                <th>User Type</th>
                                                <th>Status </th>
                                                <th>Date </th>
                                                <th>Time </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {apiDataInvoice?.slice(0, 2).map((item, index) => {
                                                const convertUTCToLocalTime = (utcDateString) => {
                                                    const date = new Date(utcDateString);
                                                    return date.toLocaleString();
                                                };
                                                const localTime = convertUTCToLocalTime(item?.paymentDate + "Z");
                                                const [datePart, timePart] = localTime.split(", ");
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.name}</td>
                                                            <td>{item?.emailId}</td>
                                                            <td>{item?.address}</td>
                                                            <td>{item?.planType}</td>
                                                            <td><b>{item?.amount}</b></td>
                                                            <td>{item?.userType}</td>
                                                            <td><span class="bg-succ">{item?.status}</span></td>
                                                            <td>{dayjs(item?.paymentDate).format("MM/DD/YYYY")}</td>
                                                            <td>{timePart}</td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </Table>

                                </div>
                            </Card>

                        </Col>
                        <Col md="6">
                            <Card className="revenue-sec card notification-admin">
                                <Row>
                                    <Col md="6" xs="6">  <h2>Notifications</h2>
                                    </Col>
                                    <Col md="6" xs="6"><p className="text-right"> <Link to="/WebAdmin/Admin_notification" className='view-List'>View All</Link></p></Col>
                                </Row>

                                {
                                    notificationMes == null ? (
                                        <div>No data found!</div>
                                    ) : (
                                        notificationMes.map((item) => {
                                            const convertUTCToLocalTime = (utcDateString) => {
                                                const date = new Date(utcDateString);
                                                return date.toLocaleString();
                                            };
                                            const localTime = convertUTCToLocalTime(item?.createDate + "Z");
                                            const [datePart, timePart] = localTime.split(", ");

                                            return (
                                                <div className="notification pt-3" key={item.id}> {/* Assuming item has a unique id */}
                                                    <div className="notification-right">
                                                        <div className="profile-pic2">
                                                            {itemData} {itemLastData}
                                                        </div>
                                                        <div className="ms-3">
                                                            <h1>{item?.userName}</h1>
                                                            <p>
                                                                {item?.message}
                                                                <br />
                                                                <span>{dayjs(item?.createDate).format("MM/DD/YYYY")}</span>
                                                                <br></br>
                                                                <span>{timePart}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )
                                }

                            </Card>

                        </Col>
                    </Row>

                </div>
                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>

        </>
    )
}
export default Dashboard;

