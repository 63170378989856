import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { RemovRoles, RemoveRefreshToken, RemoveToken, RemoveUser, SetRefreshToken, SetToken } from '../../utils/helper/helper';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { ImageUrl, REFRESH_TOKEN_API } from '../../utils/config/config';
import * as signalR from '@microsoft/signalr'
import * as icons from "react-bootstrap-icons";

import { toast } from 'react-toastify';
import { ManagerGetById, PlayerGetById } from '../../redux/auth-reducer/auth-service';

function TopNavbar({ sidebarfun }) {

    const roles = localStorage.getItem("role")
    const [userData, setUserData] = useState();
    const [showNotification, setShowNotification] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [hubConnection, setHubConnection] = useState(null)
    const [signalRNotification, setSignalRNotification] = useState([])
    const [signalRQuery, setSignalRQuery] = useState([])
    const [notificationCount, setNotificationCount] = useState()
    const [queryCount, setQueryCount] = useState();

    const navigate = useNavigate();
    async function logout() {

        RemoveToken();
        RemoveRefreshToken();
        // RemoveUser();
        RemovRoles();
        localStorage.removeItem('Role');
        // localStorage.removeItem('userData');
        navigate("/Home");

        localStorage.removeItem('sesstionID');
    }
    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser.userId;
    const isRole = localStorage.getItem('role');

    useEffect(() => {
        if (isRole == "Manager") {
            const UserGet = async () => {
                await ManagerGetById(userId).then((res) => {
                    setUserData(res?.data);
                    console.log(res, "manager");
                });
            };
            UserGet();
        }
        else if (isRole == "Player") {
            const UserGet = async () => {
                await PlayerGetById(userId).then((res) => {
                    setUserData(res?.data);
                    console.log(res, "player id");
                });
            };
            UserGet();
        } else if (isRole == "Web Admin") {
            const UserGet = async () => {
                await ManagerGetById(userId).then((res) => {
                    setUserData(res?.data);
                    console.log(res, "web admin id");
                });
            };
            UserGet();
        }

    }, [])

    const fullName = userData?.firstName + ' ' + userData?.lastName;

    useEffect(() => {
        // Check if fullName is undefined or an empty string
        if (!fullName || fullName.trim() === '') {
            RemoveToken();
            RemoveRefreshToken();
            RemoveUser();
            RemovRoles();

            navigate('/login'); // Navigate to login page
        }
    }, [fullName, navigate]);

    useEffect(() => {
        const createHubConnection = async () => {
            const Token = localStorage.getItem('isToken')
            const hubConnect = new signalR.HubConnectionBuilder()
                .withUrl("https://staging.xcutta.com/backend/hubs", {
                    // https://gadienterprises-gamc-qa.chetu.com/hubs
                    // https://staging.xcutta.com/backend/hubs
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => Token,
                })
                .withAutomaticReconnect()
                .build();
            try {
                await hubConnect.start();

            } catch (err) {

            }
            setHubConnection(hubConnect);
        };
        createHubConnection();
    }, []);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on("ReceiveNotification", (data) => {
                setSignalRNotification(data);

                setNotificationCount(data?.length)
            });

            hubConnection.on("ContactUs", (queryData) => {
                console.log(queryData[0], "queryData");
                setSignalRQuery(queryData[0]);
                console.log(queryData, "queryData")
                setQueryCount(queryData?.length)
            });

        }
    }, [hubConnection]);


    const handleLandingPage = () => {
        navigate("/");
    }

    const handleQueryPage = () => {
        console.log("click");
        navigate("/WebAdmin/Query");
    }

    const handleNotificationClick = () => {
        setShowNotification(!showNotification)
    };

    /******************* */

    /*************************** */







    const handleQueryClick = () => {
        setShowQuery(!showQuery)
    };

    const TokenInvalid = () => localStorage.getItem("TokenInvalid");
    console.log(TokenInvalid,"dfsff")
    useEffect(() => {
      let tokendata=TokenInvalid();
      if(tokendata=="true")
      {
        toast.error("Session has timeout. Please login");
        setTimeout(() => {
        //   setLoading(false);
        SetToken(null);
        SetRefreshToken(null);
        localStorage.removeItem("TokenInvalid");
          navigate("/login");
        }, 2000);
      }
    }, [TokenInvalid()]);

    const [isLoggedOut, setIsLoggedOut] = useState(false);
    let idleTimeout;

    const logoutPage = () => {
        RemoveToken();
        RemoveRefreshToken();
        RemovRoles();
        alert("Session has timed out. Please log in.");
        setIsLoggedOut(true);
        localStorage.removeItem('Role');
        localStorage.removeItem('checkoutValue');
        localStorage.removeItem('sessionID');
        window.location.href = "/login"; // Redirect to login page
        console.log("User logged out due to inactivity.");
    };

    useEffect(() => {
        const resetIdleTimer = () => {
            clearTimeout(idleTimeout);
            if (!isLoggedOut) {
                // Show toast message when session is about to expire
                idleTimeout = setTimeout(() => {

                    logoutPage(); // Call logoutPage after showing the toast
                }, 120000); // 2 seconds of inactivity
            }
        };

        // Only set up event listeners if the user is not logged out
        if (!isLoggedOut) {
            window.addEventListener('mousemove', resetIdleTimer);
            window.addEventListener('keypress', resetIdleTimer);
            resetIdleTimer(); // Start the idle timer
        }

        // Cleanup function
        return () => {
            clearTimeout(idleTimeout);
            window.removeEventListener('mousemove', resetIdleTimer);
            window.removeEventListener('keypress', resetIdleTimer);
        };
    }, [isLoggedOut]);

    const handleNotificationPageManager = () => {
        console.log("click");
        navigate("/SuperAdmin/SuperNotification");
    }

    const handleNotificationPagePlayer = () => {
        console.log("click");
        navigate("/Player/Notification");
    }

    return (
        <>
            <div className="topbar">
                <Container fluid>

                    <Row className='align-items-center '>
                        <Col xs={6} className=' mob'> <div className='logo-image'>
                            <Link to="/Home">
                                <img src="/image/logo1.png" width={115}></img>
                            </Link>

                        </div></Col>
                        <Col xs={6} className='text-right mob'><img src="../image/minu-icon.svg" onClick={sidebarfun} className='icon-menue'></img></Col>

                        <Col md="6" className='desk'>
                            <div className='innetr-group p-2'>

                                <div className='group-top-bar ms-0'>
                                    <div className='logo-image'>
                                        <Link to="/Home">
                                            <img src="/image/logo1.png" width={115}></img>
                                        </Link>
                                    </div>
                                    <div className='icon-bar'>
                                        <img src="/image/minu-icon.svg" onClick={sidebarfun} className='icon-menue'></img>
                                    </div>

                                </div>
                            </div>
                        </Col>

                        <Col md="6">
                            <div className='d-flex p-2 align-items-center justify-content-end dropdown-section'>

                                <div className='me-3' onClick={handleLandingPage}><icons.HouseDoor className='home-icon-top'></icons.HouseDoor></div>
                                {
                                    roles == "Web Admin" ?
                                        <Dropdown className='notification-message'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                <icons.Bell className='bell-notify' onClick={handleQueryClick} />
                                                {
                                                    signalRQuery?.length == 0 ? null : <span class="badge-notification" onClick={handleQueryClick}>

                                                        !
                                                    </span>
                                                }

                                            </Dropdown.Toggle>
                                            {
                                                signalRQuery?.length == 0 ? <Dropdown.Menu>
                                                    No Data Found
                                                </Dropdown.Menu> :
                                                    <Dropdown.Menu>
                                                        {
                                                            showQuery ? (
                                                                <div onClick={handleQueryPage}>

                                                                    {signalRQuery?.message}

                                                                </div>
                                                            ) : null
                                                        }
                                                    </Dropdown.Menu>
                                            }

                                        </Dropdown>

                                        : roles == "Manager" ?

                                            < Dropdown className='notification-message'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                    <icons.Bell className='bell-notify' onClick={handleNotificationClick} />
                                                    {
                                                        signalRNotification?.length == 0 ? null : <span class="badge-notification" onClick={handleNotificationClick}>

                                                            !
                                                        </span>
                                                    }

                                                </Dropdown.Toggle>
                                                {
                                                    signalRNotification?.length == 0 ? <Dropdown.Menu>
                                                        No Data Found
                                                    </Dropdown.Menu> :
                                                        <Dropdown.Menu>
                                                            {
                                                                showNotification ? (
                                                                    <div onClick={handleNotificationPageManager}>

                                                                        {signalRNotification?.message}

                                                                    </div>
                                                                ) : null
                                                            }
                                                        </Dropdown.Menu>
                                                }

                                            </Dropdown>

                                            : < Dropdown className='notification-message'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                    <icons.Bell className='bell-notify' onClick={handleNotificationClick} />
                                                    {
                                                        signalRNotification?.length == 0 ? null : <span class="badge-notification" onClick={handleNotificationClick}>

                                                            !
                                                        </span>
                                                    }

                                                </Dropdown.Toggle>
                                                {
                                                    signalRNotification?.length == 0 ? <Dropdown.Menu>
                                                        No Data Found
                                                    </Dropdown.Menu> :
                                                        <Dropdown.Menu>
                                                            {
                                                                showNotification ? (
                                                                    <div onClick={handleNotificationPagePlayer}>

                                                                        {signalRNotification?.message}

                                                                    </div>
                                                                ) : null
                                                            }
                                                        </Dropdown.Menu>
                                                }

                                            </Dropdown>
                                }

                                {userData?.imageName == null ? <img src="/image/profileDefault.png" width="50" className='rounded-circle me-3'></img> : <img src={ImageUrl + userData?.imageName} className='profile-image'></img>}
                                {/* {img === null ? <><img src="/image/profileDefault.png" width="50" className='rounded-circle me-3'></img> </> : <><img src={ImageUrl + img} className='profile-image'></img></>} */}
                                <Dropdown>
                                    {roles}
                                    <Dropdown.Toggle variant="none" className='d-flex align-items-center p-0'>

                                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip1">

                                            {isUser?.fullName}
                                        </Tooltip>}>
                                            <div className='text-hide'> {isUser?.fullName} </div>
                                        </OverlayTrigger>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        {roles == "Web Admin" ? <Dropdown>
                                            <Link to="/WebAdmin/profile" className='item-link'>
                                                Profile</Link>
                                        </Dropdown> : roles == "Super Admin" ? <Dropdown>
                                            <Link className='item-link' to="/SuperAdmin/Profile">
                                                Profile</Link>
                                        </Dropdown> : <Dropdown>
                                            <Link className='item-link' to="/Player/Profile">
                                                Profile</Link>
                                        </Dropdown>}

                                        {
                                            roles == "Web Admin" ? <Dropdown >
                                                <Link className='item-link' to="/WebAdmin/ChangePassword">
                                                    Change Password
                                                </Link></Dropdown>
                                                : roles == "Super Admin" ? <Dropdown >
                                                    <Link className='item-link' to="/SuperAdmin/ChangePassword">
                                                        Change Password
                                                    </Link></Dropdown> : <Dropdown >
                                                    <Link className='item-link' to="/Player/ChangePassword">
                                                        Change Password
                                                    </Link></Dropdown>
                                        }

                                        <Dropdown.Item onClick={logout} >
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </div >

        </>
    )
}
export default TopNavbar;


