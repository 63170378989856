import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { UserAll, ManagerGetPlayerRequest, managerApproval } from '../../redux/auth-reducer/auth-service';
import { Footer } from '../../Footer';
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import dayjs from 'dayjs';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer, toast } from 'react-toastify';

export const UserSuper = () => {
  const [data, setData] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [approvedPlayer, setApprovedlayer] = useState([]);
  const [pendingPlayer, setPendinglayer] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [approvedsearchText, setApprovedsearchText] = useState('');
  const [pendingsearchText, setPendingsearchText] = useState('');

  function sidebarfun() {
    setData(!data)
  }
  useEffect(() => {
    dataApi()
  }, []);

  const dataApi = async (event) => {
    await UserAll().then((res) => {

      setApiData(res?.data?.data?.dataList)
    })
  }

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA?.firstName?.toLowerCase();
    const b = rowB?.firstName?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: <strong>Id</strong>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => <>{row?.firstName + " " + row?.lastName}</>,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },

    {
      name: <strong>Email</strong>,
      selector: (row) => row?.emailId,
      sortable: true,
    },

    {
      name: <strong>Join Date</strong>,
      selector: (row) => dayjs(row?.createdDate).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: <strong>Join Time</strong>,
      selector: (row) => dayjs(row?.createdDate).format("HH:mm:ss"),
      sortable: true,
    },

    {
      name: <strong>Status</strong>,
      selector: (row) => (
        <>
          {row?.isPlayer == false ? (
            <div className="bg-succ">Active</div>
          ) : (
            <div className="bg-dang">InActive</div>
          )}
        </>
      ),
      sortable: true,
      width: "200px",
    },
  ];

  const approveManager = async (playerId, approval) => {
    await managerApproval(playerId, approval).then((response) => {

      if (response.status == true) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    })

  }

  const handleApprove = (id) => {
    approveManager(id, true)
  }
  const handleDecline = (id) => {
    approveManager(id, false)
  }

  const ApprovedColumns = [
    {
      name: <strong>Id</strong>,
      selector: (row, index) => index + 1,

      width: "80px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => <>{row?.playerName}</>,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },

    {
      name: <strong>Requested Date</strong>,
      selector: (row) => dayjs(row?.requestDate).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: <strong>Response Date</strong>,
      selector: (row) => dayjs(row?.responseDate).format("MM/DD/YYYY"),
      sortable: true,
    },

    {
      name: <strong>Status</strong>,
      selector: (row) => (
        <>
          {row.status == "Approved" ? (
            <div className="bg-succ">Approved</div>
          ) : (
            <div className="bg-dang">Pending</div>
          )}
        </>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: <strong>Action</strong>,
      selector: (row) => (
        <>

          {row.status == "Approved" ? (
            <button className="bg-dang" onClick={() => handleDecline(row?.playerId, false)} >Disable</button>
          ) : (
            <button className="bg-succ" onClick={() => handleApprove(row?.playerId, true)}>Enable</button>
          )}

        </>
      ),
      sortable: true,
    },
  ];

  const PendingColumns = [
    {
      name: <strong>Id</strong>,
      selector: (row, index) => index + 1,

      width: "80px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => <>{row?.playerName}</>,
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },

    {
      name: <strong>Requested Date</strong>,
      selector: (row) => dayjs(row?.requestDate).format("MM/DD/YYYY"),
      sortable: true,
    },

    {
      name: <strong>Status</strong>,
      selector: (row) => (
        <>
          {row.status == "Pending" ? (
            <div className="bg-succ">Pending</div>
          ) : (
            <div className="bg-dang">Diclined</div>
          )}
        </>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: <strong>Action</strong>,
      selector: (row) => (
        <>

          {row.status == "Pending" ? (
            <>
              <button className="bg-succ" onClick={() => handleApprove(row?.playerId, true)}>Approve</button>
              <button className="bg-dang" onClick={() => handleDecline(row?.playerId, false)}>Decline</button>
            </>
          ) : row.status == "Declined" ? (
            <button className="bg-succ" onClick={() => handleApprove(row?.playerId, true)} >Enable</button>
          ) : (
            ""
          )}

        </>
      ),
      sortable: true,
    },
  ];
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleApprovedSearch = (e) => {
    setApprovedsearchText(e.target.value);
  };
  const handlePendingSearch = (e) => {
    setPendingsearchText(e.target.value);
  };

  const filteredData = searchText
    ? apiData.filter((item) => {
      const fullName = `${item?.firstName || ""} ${item?.lastName || ""
        }`.toLowerCase();
      return (
        fullName?.includes(searchText?.toLowerCase()) ||
        item?.emailId?.includes(searchText?.toLowerCase())
      );
    })
    : apiData;

  const approvedFilteredData = approvedsearchText
    ? approvedPlayer?.filter((item) => {
      return (
        item?.playerName?.toLowerCase().includes(approvedsearchText?.toLowerCase())
      );
    })
    : approvedPlayer;

  const pendingFilteredData = pendingsearchText
    ? pendingPlayer.filter((item) => {
      return (
        item?.playerName?.toLowerCase().includes(pendingsearchText?.toLowerCase())
      );
    })
    : pendingPlayer;

  const isUser = JSON.parse(localStorage.getItem('isUser'));
  const managerId = isUser?.userId

  const GetPlayerRequest = async () => {
    await ManagerGetPlayerRequest(managerId).then((res) => {
      const ApprovedPlayers = res?.data?.filter(player => player?.status === "Approved");
      const PendingPlayers = res?.data?.filter(player => player?.status === "Pending" || player?.status === "Declined");
      setApprovedlayer(ApprovedPlayers);
      setPendinglayer(PendingPlayers)

    })
  }

  useEffect(() => {
    GetPlayerRequest()
  }, []);

  return (
    <>
      <ToastContainer />

      <TopNavbar sidebarfun={sidebarfun} />

      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>

          <Row>
            <Col md="10"><h1 className="heading-dash">Player</h1></Col>
            <Col md="2 text-right mb-3">
            </Col>
          </Row>

          <div className="group_data_table tabs-auction">
            <div className="tabs-box">
              <Tabs
                defaultActiveKey="All"
                id="auctiontabs"
                className="mb-0 tab-list-item"
                justify
              >
                <Tab eventKey="All" title="All">
                  <div>
                    <div className="auctiontabs player">
                      <div className='search-input filter auction-filter'>
                        <label className='me-3'>Search</label>
                        <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />
                      </div>

                      <DataTable
                        title="Player List"
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationPerPage={10}
                        defaultSortFieldId={1}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        paginationTotalRows={filteredData?.length}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Approved" title="Approved">
                  <div>
                    <div className="auctiontabs player">
                      <div className='search-input filter auction-filter'>
                        <label className='me-3'>Search</label>
                        <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={approvedsearchText} onChange={handleApprovedSearch} />
                      </div>

                      <DataTable
                        title="Player List"
                        columns={ApprovedColumns}
                        data={approvedFilteredData}
                        pagination
                        paginationPerPage={10}
                        defaultSortFieldId={1}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        paginationTotalRows={approvedFilteredData?.length}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Pending" title="Pending">
                  <div>
                    <div className="auctiontabs player">
                      <div className='search-input filter auction-filter'>
                        <label className='me-3'>Search</label>
                        <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={pendingsearchText} onChange={handlePendingSearch} />
                      </div>

                      <DataTable
                        title="Player List"
                        columns={PendingColumns}
                        data={pendingFilteredData}
                        pagination
                        paginationPerPage={10}
                        defaultSortFieldId={1}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        paginationTotalRows={pendingFilteredData?.length}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>

          </div>

        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  )
}
