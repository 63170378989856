import React, { useEffect, useState } from 'react'
import { Footer } from '../../Footer'
import DataTable from 'react-data-table-component'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import * as icons from 'react-bootstrap-icons';
import { SportAllMap, TeamDelete, TeamGetAll, TeamReg, TeamUpdate, UploadImage } from '../../redux/auth-reducer/auth-service'
import { ImageUrl } from '../../utils/config/config'
import { ModalBox, ModalDelete } from '../Outer/Modal'
import { ToastContainer, toast } from 'react-toastify'
import { Loader } from 'rsuite'

export const Team = () => {
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [teamImage, setTeamImage] = useState('');
    const [itemList, setItemList] = useState('');
    const [sportData, setSportData] = useState([]);
    const [count, setCount] = useState(0);
    const [deleteRow, setDeleteRow] = useState(0);
    const [showEdit, setShowEdit] = useState();
    const [dataImage, setDataImage] = useState();
    const [imageResponse, setImageResponse] = useState();
    const [dataImageUpdate, setDataImageUpdate] = useState();
    const [pageSize, setPageSize] = useState();
    const [editData, setEditData] = useState({
        sportsID: 0,
        teamImage: "",
        sportsName: "",
        sportsId: 0
    });
    const [searchText, setSearchText] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageAdd, setSelectedImageAdd] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [teamReg, setTeamReg] = useState({
        teamId: "",
        teamName: "",
        teamImage: "",
        sportsId: 0
    })

    function sidebarfun() {
        setData(!data)
    }

    const TeamAllFn = async (data) => {
        await TeamGetAll(data).then((response) => {
            setItemList(response?.data?.data?.dataList);
            setPageSize(response?.data?.data?.totalPageSize);
        })
    }

    useEffect(() => {
        const data = {
            "PageNumber": 1,
            "PageSize": perPage
        }
        TeamAllFn(data);
    }, [count])

    const handlePageChange = page => {
        setPageNo(page);
        const data = {
            "PageNumber": page,
            "PageSize": perPage
        }
        TeamAllFn(data);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        const data = {
            "PageNumber": page,
            "PageSize": newPerPage
        };
        await TeamAllFn(data);
    };

    useEffect(() => {

        const SportData = async () => {
            await SportAllMap().then((response) => {
                setSportData(response?.data?.data?.dataList)

            })
        }

        SportData();

    }, [])





    const columns = [
        {
            name: <strong>ID</strong>,
            selector: (row, index) => index + 1,

            width: "80px",
        },
        {
            name: <strong>Logo</strong>,
            selector: (row) => (

                <>
                    {row.teamImage == "" ? (
                        <img src="../image/team-icon.png" width={100} className="img-fluid p-3" />
                    ) : (

                        <img
                            src={ImageUrl + row?.teamImage}
                            width={100}
                            className="img-fluid p-3"
                        ></img>
                    )}
                </>
            ),
            sortable: true,
        },
        {
            name: <strong>Team Name</strong>,
            selector: (row) => row?.teamName,
            sortable: true,
        },

        {
            name: <strong>Sport Name</strong>,
            selector: (row) => row?.sportsName,
            sortable: true,
        },

        {
            name: <strong>Action</strong>,
            selector: (row) => (
                <>
                    <button
                        type="button"
                        className="text-danger btn-aucion"
                        onClick={() => deleteFn(row?.teamId)}
                    >
                        <icons.Trash />
                    </button>
                    <button
                        type="button"
                        className="text-success btn-aucion"
                        onClick={() => editFn(row)}
                    >
                        <icons.PencilSquare />
                    </button>
                </>
            ),
            sortable: true,
            width: "150px",
        },
    ];

    const editFn = (row) => {
        setShowEdit(true);
        setEditData(row, "")

    }

    const deleteFn = async (teamId) => {
        setShowDeleteModal(true);
        setDeleteRow(teamId)
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const filteredData = searchText
        ? itemList?.filter((item) => {
            return item?.teamName?.toLowerCase().includes(searchText?.toLowerCase()) || item?.sportsName?.toLowerCase().includes(searchText?.toLowerCase());
        })
        : itemList;

    const AddTeam = () => {
        setShow(true);
    }
    const handleClosed = () => {
        setShowDeleteModal(false);
        setSelectedImage("")
        setShow(false);
        setShowEdit(false);
        setIsButtonDisabled(true);
    };

    const handelTeam = (e) => {

        setTeamReg({
            ...teamReg,
            [e.target.name]: e.target.value
        }
        )
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setLoading(true);
        if (form.checkValidity() == 0) {
            setValidated(true);
        }
        if (imageResponse?.status == false) {
            toast.error("Please upload image file minimume 2mb size.");
            return null
        }

        const registerData = [{
            "crudOperationId": true,
            "teamId": 0,
            "teamName": teamReg.teamName,
            "teamImage": dataImage || "",
            "sportsId": Number(teamReg.sportsId)
        }]

        await TeamReg(registerData).then((res) => {
            if (res?.data?.status === true) {
                setSelectedImageAdd("")
                setCount(count + 1);
                setIsButtonDisabled(true);
                toast.success(res?.data?.message);
                setShow(false);
                setLoading(false)
                setDataImage('')
                setValidated(false)

                setTeamReg({
                    teamId: "",
                    teamName: "",
                    teamImage: "",
                    sportsId: ""
                })
            }
            else {
                toast.error(res?.message);
            }
        })

    }

    const handleFileChange = (event) => {
        setIsButtonDisabled(false);
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (event) {
                setSelectedImageAdd(event.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }

        setSelectedFile(event.target.files[0])
        const dataList = (event.target.files[0]);
        const imageAPI = async () => {
            const formDataImage = new FormData();
            formDataImage.append('file', dataList);
            await UploadImage(formDataImage).then((res) => {

                setImageResponse(res)
                if (res?.data?.status == true) {

                    setDataImage(res?.data?.message);

                } else {
                    toast.error(res?.message);

                }
            })
        }

        imageAPI()
    }

    const handelDelete = async () => {
        await TeamDelete(deleteRow).then((res) => {
            if (res?.status == true) {
                setCount(count + 1);
                toast.success(res?.message);
                setIsButtonDisabled(true);
                setShowDeleteModal(!showDeleteModal);
            } else {
                toast.error(res?.message)
            }

        })
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        setValidated(true);
        const registerData = teamImage ? {
            "crudOperationId": false,
            "teamId": editData?.teamId,
            "teamName": editData.teamName,
            "teamImage": dataImageUpdate || "",
            "sportsId": parseInt(editData?.sportsId)
        } : {
            "crudOperationId": false,
            "teamId": editData?.teamId,
            "teamName": editData?.teamName,
            "teamImage": dataImageUpdate || "",
            "sportsId": parseInt(editData?.sportsId)
        }

        await TeamUpdate(registerData).then((res) => {

            if (res?.status == true) {
                setValidated(false);
                setCount(count + 1);
                toast.success(res?.message);
                setShowEdit(false);
            }

            else {
                toast.error(res?.message);
            }
        })
    }
    const handelEditTeam = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });

    }

    const handelImage = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
        setSelectedFile(e.target.files[0])
        setTeamImage(e.target.files[0].name)

        const dataList = (e.target.files[0]);
        const imageAPI = async () => {
            const formDataImage = new FormData();
            formDataImage.append('file', dataList);
            await UploadImage(formDataImage).then((res) => {
                if (res?.data?.status) {

                    setDataImageUpdate(res?.data?.message);

                } else {

                }
            })
        }

        imageAPI()
    }

    return (
        <>



            <ToastContainer />

            <ModalDelete show={showDeleteModal} onHide={handleClosed} title="Delete Team" body={
                <>
                    Are you want to delete the team?

                </>
            } footer={
                <>
                    <Button variant="danger" onClick={handelDelete} >Delete</Button>
                    <Button variant="secondary" onClick={handleClosed} >Close</Button>

                </>
            } />

            <ModalBox show={showEdit} onHide={handleClosed} title="Update Team" body={
                <>

                    <Form noValidate validated={validated} onSubmit={handleUpdate}>
                        <Row className="mb-3">

                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Team Name</Form.Label>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Control type='text' className='form-control' maxLength={50} name="teamName" onChange={handelEditTeam} value={editData?.teamName} required /><br />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid sport name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Control name="sportsId" value={editData?.sportsId} onChange={(event) => handelEditTeam(event)} className='select-option form-control' required as="select">
                                    <option value={''}>Select Sports</option>
                                    {
                                        sportData?.map((list) =>
                                            <option key={list.sportsID} value={list.sportsId}>
                                                {list.sportsName}
                                            </option>
                                        )
                                    }
                                </Form.Control >

                                <input type='file' className='form-control' name="imageName" onChange={handelImage}  ></input>

                                {selectedImage ? <img src={selectedImage} alt="Selected" width={100} /> : <img src={ImageUrl + editData?.teamImage} width={100}></img>}

                            </Form.Group>

                        </Row>

                        <Row>
                            <Col md={6}></Col>
                            <Col md={6} className='text-right'> <Button type="submit" className='btn-Save me-3'>Update</Button>
                                <Button type="button" className='btn-Cancel secondary' onClick={handleClosed} >Cancel</Button>
                            </Col>
                        </Row>

                    </Form>

                </>
            } />




            <ModalBox show={show} title="Add Team" body={
                <>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Team Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    maxLength={50}
                                    name="teamName"
                                    valu={teamReg?.teamName}
                                    onChange={handelTeam}
                                    placeholder="Team Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid Team name.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6" className="text-left" controlId="validationCustomCountry">
                                <Form.Label>Sport Name</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select name="sportsId" onChange={handelTeam} required as="select">
                                        <option value={''}>Select Sports</option>
                                        {
                                            sportData?.map((list) =>
                                                <option key={list.sportsId} value={list.sportsId}>
                                                    {list.sportsName}
                                                </option>
                                            )
                                        }
                                    </Form.Select >

                                    <Form.Control.Feedback type="invalid">
                                        Please a select sport name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="12 mt-2" controlId="validationCustom02">
                                <Form.Label>Logo</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="teamImage"
                                    onChange={handleFileChange}
                                    placeholder="Choose file"
                                />
                                {loading && <Loader />}

                                {dataImage ? <img src={ImageUrl + dataImage} alt="Selected" width={100} /> : ""}

                                <p className='mt-2 image-upload-mess'>(jpeg, jpg, png and size upto 2 mb)<span className='text-danger'>*</span></p>
                            </Form.Group>

                        </Row>
                        <Row>
                            <Col md={6}></Col>
                            <Col md={6} className='text-right'> <Button type="submit" className='btn-Save me-3'>Save</Button>
                                <Button type="button" className='btn-Cancel secondary' onClick={handleClosed} >Cancel</Button>
                            </Col>
                        </Row>

                    </Form>

                </>
            } />

            <TopNavbar sidebarfun={sidebarfun} />
            <div className="dasboard-group" >
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <Row>
                        <Col md={6}><h1 className="heading-dash">Teams</h1></Col>
                        <Col md={6} className='text-right'>
                            <Button className='primary button-create-auction ' onClick={AddTeam}><icons.Plus class="icon-plus" /> Add Team</Button>
                        </Col>
                    </Row>

                    <div className='group_data_table team-list'>
                        <div className='search-input d-flex align-items-center'>
                            <label className='me-3'>Search</label>
                            <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />
                        </div>
                        <DataTable
                            title="Team List"
                            columns={columns}
                            data={filteredData}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30]}
                            paginationTotalRows={filteredData?.length}

                        />
                    </div>

                </div>
                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>

            </div>
        </>
    )
}


