import React from 'react'
import Headertwo from './Headertwo'
import Footertwo from './Footertwo'
import { Col, Container, Row } from 'react-bootstrap'
import Carousel from 'react-multi-carousel';

export const AboutUs = () => {
	const responsive2 = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	return (
		<>
			<div className='group-section'>
				<Headertwo />
				<div className='banner-item-About-us'>
					<div>
						<h2>Our Mission</h2>
						<h4>To provide the most powerful user-friendly sports auction software to
							sports fanatics around the world.</h4>
					</div>
				</div>


			</div>



			<section className='p-5 about-us-page'>
				<Container>
					<Row className='align-items-center'>
						<Col md="6">
							<h2 className='mb-3'>Overview</h2>
							<p>
								Xcutta was born out of a desire to combine sports prognostication
								with the thrill of a live auction. Over the past 20 years the game was performed with pen and
								paper among friends and competitors. Today, with the digital flexibility of the internet and
								power of sophisticated software, Xcutta is now available at the tip of your fingers.<br /><br />

								The “X” represents any sporting event. The “cutta” is an abbreviation of the word calcutta.
								Xcutta is the first sports auction software made available to give you as a manager the power
								to create and manage your own sporting events with live auctions.<br /><br />

								Xcutta is a game adaptable to any sporting event world wide. Whether its a professional
								football tournament, college basketball tournament, or even your local golf course weekend
								gathering, the Xcutta software allows a manager the power to choose your players or teams,
								customize your winnings, and invite players to participate in your personalized auction.<br /><br />
							</p>
						</Col>
						<Col md="6">
							<img src='../image/overview.jpg' className='img-fluid' />
						</Col>
					</Row>


					<h2 className='mb-5 text-center mt-5 '>
						<span className='heading-sports'>Our Sports</span>

					</h2>
					<Carousel
						responsive={responsive2}
						autoPlay={true}
						autoPlaySpeed={3000}
						infinite={true}
						arrows={true}
					>
						<div ><img src="../image/gallery1.jpg" className='slider-image-about'></img></div>
						<div ><img src="../image/gallery2.png" className='slider-image-about'></img></div>
						<div ><img src="../image/gallery3.png" className='slider-image-about'></img></div>
						<div ><img src="../image/gallery4.png" className='slider-image-about'></img></div>



					</Carousel>
				</Container>
			</section>

			<Footertwo />
		</>
	)
}
