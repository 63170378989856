import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import '../../assets/css/scss/main.css';
import * as icons from 'react-bootstrap-icons';
import RightSideBanner from './RightSideBanner';
import { Link, useNavigate } from 'react-router-dom';
import { GetToken, SetRefreshToken, SetToken, SetUser, setRoles } from '../../utils/helper/helper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Modal from 'react-bootstrap/Modal';
import { CheckoutAddPayment, CreatePymenentUrlFn, CustomerAddPayment, loginUserAPI } from '../../redux/auth-reducer/auth-service';
import CryptoJS from 'crypto-js';
import { REACT_APP_API_SECRET } from '../../utils/config/config';


function Login() {
  const [validated, setValidated] = useState(false);
  let [visible, setVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: ''
  })

  const [rememberMe, setRememberMe] = useState(false);
  const { email, password } = user;

  const secretPass = REACT_APP_API_SECRET;

  const encryptData = (datainfo) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(datainfo), secretPass).toString();
    return data;
  };

  const decryptData = (userData) => {
    const bytes = CryptoJS.AES.decrypt(userData, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  };

  const initialValues = {
    email: "",
    password: "",
  };


  const handleLogin = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }


  const navigate = useNavigate();





  const forgot = (event) => {
    navigate('/ForgotPassword');
  }

  useEffect(() => {
    const storedData = localStorage.getItem("userData");
    if (storedData) {
      const { userName1, password1, checkedStatus1 } = JSON.parse(storedData);
      let depdata = decryptData(password1);
      setRememberMe(checkedStatus1);
      initialValues.email = userName1;
      initialValues.password = depdata;
      setUser({
        email: userName1,
        password: depdata
      })
    }
  }, []);






  useEffect(() => {
    if (isButtonDisabled) {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);
    }
  }, [isButtonDisabled])


  const checkoutFn = async (dataCheckout) => {



    try {
      const res = await CheckoutAddPayment(dataCheckout);
      let url = res?.data?.data?.sessionUrl;
      localStorage.setItem('sesstionID', res?.data?.data?.sessionId);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Checkout failed:", error);
    }

  }

  const handelSubmitPlayer = async (arrayID, dataItem, userData) => {

    let token = GetToken()
    if (token == null) {
      localStorage.setItem("checkoutValue", JSON.stringify(selectedOption))
      navigate("/Login")
    }
    else {
      let itemPayment = localStorage.getItem("checkoutValue");


      await CreatePymenentUrlFn(itemPayment).then((res) => {
        if (res?.status == 200) {
          let url = res?.data?.data[0]?.sessionUrl;
          window.open(url, "_blank")

        } else {

        }
      });
      // const data = {
      //   "name": userData?.fullName,
      //   "email": userData?.email,
      // }
      // let customerIdValue;
      // await CustomerAddPayment(data).then((res) => {
      //   if (res?.status == 200) {
      //     customerIdValue = res?.data?.data?.customerId;
      //     localStorage.setItem('sesstionID', res?.data?.data?.sessionId);

      //     const dataCheckout = {
      //       "id": userData?.userId,
      //       "name": userData?.fullName.split(" ")[0],
      //       "email": userData?.email,
      //       "customerId": customerIdValue,
      //       "currency": "USD",
      //       "amount": dataItem * 100,
      //       "planType": arrayID.trim(),
      //       "description": "Payment",
      //     }


      //     checkoutFn(dataCheckout)


      //   } else {
      //   }
      // });



    };

  }


  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setIsButtonDisabled(true);

  //   let data2 = encryptData(password);
  //   const formData = {
  //     userName1: email,
  //     password1: data2,
  //     checkedStatus1: rememberMe,
  //   };





  //   await loginUserAPI(user).then((response) => {




  //     if (response?.data?.status == true) {
  //       const token = response?.data?.token;
  //       const refreshToken = response?.data?.refreshToken;
  //       const roles = response?.data?.role;
  //       const { fullName, userId, roleId } = response?.data
  //       const user = { fullName, userId, roleId };
  //       SetToken(token)
  //       SetRefreshToken(refreshToken)
  //       SetUser(user);
  //       setRoles(roles)
  //       if (rememberMe) {
  //         localStorage.setItem("userData", JSON.stringify(formData));
  //       } else {
  //         localStorage.removeItem("userData");
  //       }
  //       let checkoutData = localStorage.getItem('checkoutValue');
  //       console.log(checkoutData, "checkoutData")
  //       // let arrayID = checkoutData?.split('/')[1];
  //       // let dataItem = checkoutData?.split('/')[0];
  //       if (checkoutData) {
  //         handelSubmitPlayer(checkoutData)
  //         localStorage.removeItem('checkoutValue');
  //         return
  //       }


  //       if (response.data?.role == "Web Admin") {
  //         localStorage.setItem('role', response?.data?.role);
  //         localStorage.setItem('img', response?.data?.imageName);
  //         setTimeout(() => {
  //           navigate('/WebAdmin/Dashboard');
  //         }, 2000)

  //       }
  //       else if (response.data?.role == "Manager") {
  //         localStorage.setItem('role', response?.data?.role);
  //         localStorage.setItem('img', response?.data?.imageName);
  //         setTimeout(() => {
  //           navigate('/SuperAdmin/SuperDashboard');
  //         }, 2000)
  //       }
  //       else {
  //         localStorage.setItem('role', response?.data?.role);
  //         localStorage.setItem('img', response?.data?.imageName);
  //         setTimeout(() => {
  //           navigate('/Player/Player_dashboard');
  //         }, 2000)
  //       }
  //       toast.success(response?.data?.message);

  //     }
  //     else {
  //       setUser({
  //         email: '',
  //         password: ''
  //       });
  //       toast.error(response?.data?.message)
  //     }
  //   });


  //   setValidated(true);
  // };




  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);

    // Encrypt the password before sending it to the server
    let data2 = encryptData(password);
    const formData = {
      userName1: email,
      password1: data2,
      checkedStatus1: rememberMe,
    };

    try {
        const response = await loginUserAPI(user);
        
        if (response?.data?.status) {
            const { token, refreshToken, role, fullName, userId, roleId, imageName, message } = response.data;
            const user = { fullName, userId, roleId };

            // Set tokens and user data
            SetToken(token);
            SetRefreshToken(refreshToken);
            SetUser(user);
            setRoles(role);

            // Store user data based on "Remember Me" status
            if (rememberMe) {
                localStorage.setItem("userData", JSON.stringify(formData));
                console.log(formData,"userData")
            } else {

                localStorage.removeItem("userData");
            }

            // Handle navigation based on user role
            localStorage.setItem('role', role);
            localStorage.setItem('img', imageName);
            const redirectPath = role === "Web Admin" ? '/WebAdmin/Dashboard' :
                                 role === "Manager" ? '/SuperAdmin/SuperDashboard' :
                                 '/Player/Player_dashboard';
            setTimeout(() => navigate(redirectPath), 2000);

            toast.success(message);
        } else {
            // Reset user state on failure
            setUser({ email: '', password: '' });
            toast.error(response?.data?.message);
        }
    } catch (error) {
        console.error("Login error:", error);
        toast.error("An error occurred during login.");
    } finally {
        setIsButtonDisabled(false);
        setValidated(true);
    }
};
  const show = (event) => {
    setVisible(!visible)
  }

  const handleClose = () => setSmShow(false);
  const handleShow = () => setSmShow(true);

  const [selectedOption, setSelectedOption] = useState('UserRegistration');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  console.log(selectedOption,"selectOpti?")

  function subBtn() {
    if (selectedOption === 'UserRegistration') {
      navigate('/UserRegistration')
    } else if (selectedOption === 'Organization') {
      navigate('/Organization')
    }

  }
  const close = () => {
    setSmShow(false)
  }
  const backBtn = () => {
    navigate(-1)
  }





  return (
    <>
      <ToastContainer></ToastContainer>




      <Modal show={smShow} onHide={handleClose} centered>


        <img src="../image/circle_full_width.png" width="100%" height={80} />

        <Modal.Body className='pt-0'>
          <h2 className='text-center heading-get-started'><b>Get Started Now</b></h2>
          <p className='text-center mb-4 mt-3'>Create an Account as</p>

          <div className='grop-input-radio d-flex justify-content-center'>
            <Form.Check className="me-3" label=" Player" type="radio" name="flexRadioDefault" value="UserRegistration"
              checked={selectedOption === 'UserRegistration'} onChange={handleOptionChange} id="flexRadioDefault"
            />

            <Form.Check label="Xcutta Manager" type="radio" name="flexRadioDefault" value="Organization" checked={selectedOption === 'Organization'}
              onChange={handleOptionChange} id="flexRadioDefault2"
            />
          </div>
          <Row>

            <Col md={12} className='text-center mt-4 pb-5'>
              <Button className='text-center submit-btn' type="submit" onClick={subBtn}>Continue</Button>
              <Button className='text-center btn btn-danger ms-3 ' onClick={close} type="submit"  >Close</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <div className='group-reg'>
        <div className='icon-back'>
          <icons.ArrowLeft onClick={backBtn} />
        </div>
        <div className='item'>
          <div className='inner-item'>
            <div className='form-contnet'>
              <div>
            <Link to="/Home"> <img src='../image/logo1.png' className='logo-mob-img' /></Link>

              <h1 className='heading'>Welcome Back</h1>
              <p>Login into your account</p>
              </div>
            </div>
            <Form noValidate validated={validated} className='Login-form' onSubmit={handleSubmit}>
              <Row className="mb-3">

                <Form.Group as={Col} md="12" controlId="validationCustomFristname">
                  <Form.Label>Email</Form.Label>
                  <InputGroup hasValidation>

                    <icons.Person className='persion-icon' />

                    <Form.Control className='email-input'
                      type="email"
                      name='email'
                      value={email}
                      onChange={handleLogin}
                      placeholder="Enter your Email"
                      aria-describedby="inputGroupPrepend"
                      required
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter a Email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Col} md="12" className="text-left " controlId="validationCustomLastname">
                  <Form.Label>Password</Form.Label>
                  <InputGroup className='password-input' hasValidation>

                    <svg width="23px" className='persion-icon lock' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <div className='eye-icon' onClick={show}>
                      {
                        visible ? <icons.Eye /> : <icons.EyeSlash />
                      }

                    </div>



                    <Form.Control
                      type={visible ? "text" : "password"}
                      name='password'
                      value={password}
                      onChange={handleLogin}

                      className='email-input password-input-Change'
                      placeholder="Enter your Password"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter your valid password.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Col md="6">
                  <label className='rememberInput text-color-la'>
                    <input
                      type="checkbox"
                      className='input-checkbox'
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <span class="checkmark"></span>
                    Remember me
                  </label>




                </Col>


                <Col md="6" className='account-para1 ' ><span onClick={forgot}><a>Forgot Password</a></span></Col>
                <Col md="12 ">
                  <Button type="submit" disabled={isButtonDisabled} className='submit'>Login</Button>
                </Col>
              </Row>
            </Form>
            <p className='account-para'>Don't have an Account? <a href="#" onClick={handleShow} >Register here</a>

            </p>
          </div>
        </div>
        <RightSideBanner />
      </div>


    </>
  )
}

export default Login;
