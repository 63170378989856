import React, { useEffect, useState } from "react";
import Headertwo from "./Headertwo";
import imageCopy from "../../assets/images/sdssss.png";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import imageOne from "../../assets/images/img1.png";
import imagetwo from "../../assets/images/img2.png";
import imgthird from "../../assets/images/img3.png";
import icon1 from "../../assets/images/icon1.png";
import icon2 from "../../assets/images/icon2.png";
import icon3 from "../../assets/images/icon3.png";
import auctionimg from "../../assets/images/Auction-Process.png";
import Footertwo from "./Footertwo";
import { useNavigate } from "react-router-dom";
import { GetToken } from "../../utils/helper/helper";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  CheckoutAddPayment,
  CreatePymenentUrlFn,
  CustomerAddPayment,
  ManagerGetById,
  PlayerGetById,
  RedeemPromoCode
} from "../../redux/auth-reducer/auth-service";
import { Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1199 },
    items: 6,
  },
  desktop1: {
    breakpoint: { max: 1200, min: 1023 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const Home = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [userDataItem, setUserDataItem] = useState();
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setSmShow(false);
  const handleShow = () => setSmShow(true);
  const [promoCode, setPromoCode] = useState("")
  const [showcodeModel, setShowcodeModel] = useState(false);
  const [validated, setValidated] = useState(false);
  const handlecodeModelClose = () => {
    setShowcodeModel(false)
    setPromoCode("")
  };
  const handleCodeModelShow = () => setShowcodeModel(true);

  function subBtn() {
    if (selectedOption === "UserRegistration") {
      navigate("/UserRegistration");
    } else if (selectedOption === "Organization") {
      navigate("/Organization");
    }
  }
  const close = () => {
    setSmShow(false);
  };
  const redeemPromoCode = (event) => {
    event.preventDefault();
    setValidated(true);
    let token = GetToken();
    if (token == null) {
    navigate("/userRegistration")
    }
    else{
      const GetCode = async () => {


        await RedeemPromoCode(promoCode).then((res) => {
        
          if (res?.data?.status === true) {
            setValidated(false);
            toast.success(res?.data?.message);
            handlecodeModelClose();
            setPromoCode("")
          } else {
            toast.error(res?.data?.message);
            setValidated(false);
          }
        })
      }
      GetCode()
    }

   

      
  

  }

  const handlePromoCode = (e) => {
    setPromoCode(
      [e.target.name] = e.target.value,
    );
  }
  const Role = localStorage.getItem("role");

  const handelSubmit = async (event) => {
    navigate("/Login");
    // let arrayID = selectedOption?.split("/")[1];
    // let dataItem = selectedOption?.split("/")[0];

    // let token = GetToken();
    // event.preventDefault();
    // if (token == null) {
    //   localStorage.setItem("checkoutValue", selectedOption);

    // } else {
    //   const data = {
    //     name: userData?.firstName,
    //     email: userData?.emailId,
    //   };
    //   let customerIdValue;
    //   await CustomerAddPayment(data).then((res) => {
    //     if (res?.status == 200) {
    //       customerIdValue = res?.data?.data?.customerId;

    //     } else {

    //     }
    //   });

    //   const dataCheckout = {
    //     name: userData?.firstName,
    //     email: userData?.emailId,
    //     customerId: customerIdValue,
    //     currency: "USD",
    //     amount: dataItem * 100,
    //     planType: arrayID.trim(),
    //     description: "Payment",
    //   };
    //   await CheckoutAddPayment(dataCheckout).then((res) => {
    //     localStorage.setItem('sesstionID', res?.data?.data?.sessionId);
    //     let url = res?.data?.data?.sessionUrl;
    //     window.open(url, "_blank");
    //   });
    // }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handelSubmitPlayer = async (event) => {
    let arrayID = selectedOption?.split("/")[1];
    let dataItem = selectedOption?.split("/")[0];
    let token = GetToken();
    event.preventDefault();
    if (token == null) {
      localStorage.setItem("checkoutValue", selectedOption);
      navigate("/Login");
    } else {

      localStorage.setItem("checkoutValue", selectedOption);
      await CreatePymenentUrlFn(selectedOption).then((res) => {
        if (res?.status == 200) {
          console.log(res, "res????")
          let url = res?.data?.data[0]?.sessionUrl;
          window.open(url, "_blank")

        } else {

        }
      });
      // const data = {
      //   name: userDataItem?.firstName,
      //   email: userDataItem?.emailId,
      // };
      // let customerIdValue;

      // await CustomerAddPayment(data).then((res) => {
      //   if (res?.status == 200) {
      //     customerIdValue = res?.data?.data?.customerId;
      //   } else {

      //   }
      // });

      // const dataCheckout = {
      //   name: userDataItem?.firstName,
      //   email: userDataItem?.emailId,
      //   customerId: customerIdValue,
      //   currency: "USD",
      //   amount: dataItem * 100,
      //   planType: arrayID.trim(),
      //   description: "Payment",
      // };
      // await CheckoutAddPayment(dataCheckout).then((res) => {
      //   let url = res?.data?.data?.sessionUrl;

      //   localStorage.setItem('sesstionID', res?.data?.data?.sessionId);
      //   window.open(url, "_blank");
      // });
    }
  };

  useEffect(() => {
    async function ManagerGet() {
      const isUser = JSON.parse(localStorage.getItem('isUser'));
      const userId = isUser?.userId;


      await ManagerGetById(userId).then((res) => {

        setUserData(res?.data);
      });
    }
    ManagerGet();
  }, []);

  useEffect(() => {
    const UserGet = async () => {
      const isUser = JSON.parse(localStorage.getItem('isUser'));
      const userId = isUser?.userId;

      await PlayerGetById(userId).then((res) => {
        setUserDataItem(res?.data);

      });
    };
    UserGet();
  }, []);

  const handleScroll = () => {

    const section = document.getElementById("mySection");
    section.scrollIntoView({ behavior: "smooth" });
  };

  const isUserExists = localStorage.getItem('role');

  return (
    <>
      <Headertwo />
      <ToastContainer></ToastContainer>

      <Modal show={showcodeModel} onHide={handlecodeModelClose} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Form noValidate validated={validated} onSubmit={redeemPromoCode}>
          <Modal.Header closeButton className="promo-code-header className promo-close">
            <Modal.Title>Redeem Promo Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group as={Col} md="12" controlId="validationCustomFristname">
              <Form.Label>Promo Code</Form.Label>
              <InputGroup hasValidation>


                <Form.Control
                  type="text"
                  name='promoCode'
                  value={promoCode}
                  onChange={handlePromoCode}
                  placeholder="Enter promo code"
                  aria-describedby="inputGroupPrepend"
                  required
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter a Promo Code.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlecodeModelClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" >
              Redeem now
            </Button>
          </Modal.Footer>
        </Form>

      </Modal>
      <section className="banner">
        <div className="container-fluid">
          <div className="row">
            <Col>
              <div class="p-2 text-white text-left text-area">
                <h1 className="heading">The Ultimate<br></br>
                  Sports Auction <br></br>Software</h1>

                <p className="paragraph mb-3 mt-3">
                  Create a powerful customized online sports auction to compete against your chosen competitors
                </p>
                <Button
                  variant="outline-success1"
                  className="btnone"
                  onClick={handleScroll}
                >
                  Get Started{" "}
                </Button>
                {!isUserExists && (
                  <Button
                    variant="primary"
                    className="btntwo"
                    onClick={handleShow}
                  >
                    Join Now
                  </Button>
                )}

              </div>
            </Col>

            <img src={imageCopy} className="image_one" />
          </div>
        </div>
      </section>
      <Modal show={smShow} onHide={handleClose} centered>
        <img src="../image/circle_full_width.png" width="100%" height={80} />

        <Modal.Body className="pt-0">
          <h2 className="text-center heading-get-started">
            <b>Create an account as</b>
          </h2>
          <p className="text-center mb-4 mt-3"></p>

          <div className="grop-input-radio d-flex justify-content-center">
            <Form.Check
              className="me-3"
              label=" Player"
              type="radio"
              name="flexRadioDefault"
              value="UserRegistration"
              checked={selectedOption === "UserRegistration"}
              onChange={handleOptionChange}
              id="flexRadioDefault"
            />

            <Form.Check
              label="Xcutta Manager"
              type="radio"
              name="flexRadioDefault"
              value="Organization"
              checked={selectedOption === "Organization"}
              onChange={handleOptionChange}
              id="flexRadioDefault2"
            />
          </div>
          <Row>
            <Col md={12} className="text-center mt-4 pb-5">
              <Button
                className="text-center submit-btn"
                type="submit"
                onClick={subBtn}
              >
                Continue
              </Button>
              <Button
                className="text-center btn btn-danger ms-3 "
                onClick={close}
                type="submit"
              >
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <section className="padding">
        <div class="container">
          <div className="row">
            <div className="col postion">
              <div class="second card cardone">
                <img src={imageOne} className="image-card" height={346} />
                <div class="title">
                  <h1>eBidding software </h1>
                  <h1>built for your needs</h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div class="second card cardtwo">
                <img src={imagetwo} className="image-card" height={346} />
                <h1 className="headingcenter">More than 1000 teams and players<br></br> available in data base</h1>
              </div>
            </div>
            <div className="col postion">
              <div class="second card thirdcard">
                <img src={imgthird} className="image-card" height={346} />
                <div class="thirdimg">
                  <h1>Customize your auction and winnings</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <p className="paragraphtext">
              Create your digital sports auction contest and invite competitors from around the world.
            </p>
          </div>
        </div>
      </section>


      <section className="section-auction padding2">
        <div class="container">
          <div className="row text-center">
            <h1 className="pt-5">Streamline Your Auction Process</h1>
            <div className="col-sm-4">
              <div class="second card padding-section section-text-area">
                <div class="title2 text-center">
                  <img src={icon1} className="iconss" />
                  <h1 className="pt-4 pb-4">Get Started Instantly</h1>
                  <h2>
                    Register for Free as a Xcutta Manager and explore all the possibilities.
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div class="second card padding-section section-text-area">
                <div class="title2 text-center">
                  <img src={icon2} className="iconss" />
                  <h1 className="pt-4 pb-4">Take Control of Your Data</h1>
                  <h2>
                    Oversee all your data—from users to bids, and more—and
                    utilize web analytics to understand which of your activities
                    drive bids.
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div class="second card padding-section section-text-area">
                <div class="title2 text-center">
                  <img src={icon3} className="iconss" />
                  <h1 className="pt-4 pb-4">Access From Anywhere</h1>
                  <h2>
                    Customize your Xcutta Player List by recruiting competitors from around the world.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="auction-img">Play Xcutta</h1>
      </section>
      {Role == "Web Admin" ? "" : Role == "Manager" ?

        <section className="section-auction padding2 bg-img">
          <div class="container" id="mySection">
            <div className="row text-center">
              <h1 className="pt-5 text-white">Register Now to Play</h1>
              <div className="col">
                <div class="second card padding-section subscrption-card">
                  <div class="title2 text-center">
                    <h1 className=" pb-4 title-text">Xcutta Manager</h1>
                    <h2 className="pb-2">
                      Register for free to create your own Sport Auctions, Tournaments, and
                      many more.
                    </h2>
                    {/* <form onSubmit={handelSubmit}>
                      <label className="w-100 group-input-feild">
                        <input
                          type="radio"
                          value="6.99 / Monthly"
                          checked={selectedOption === "6.99 / Monthly"}
                          class="input-radio-button"
                          onChange={handleOptionChange}
                          id="flexRadioDefault2"
                        />
                        <span class="price-value">$6.99 / Month</span>
                      </label>

                      <p className="or">Or</p>

                      <label className="w-100 group-input-feild">
                        <input
                          type="radio"
                          value="69.00 / Yearly"
                          class="input-radio-button"
                          checked={selectedOption === "69.00 / Yearly"}
                          onChange={handleOptionChange}
                          id="flexRadioDefault1"
                        />
                        <span class="price-value">$69 / Year</span>
                      </label>
                      <br />
                      <Button type="submit" className="btn-outline-success-three">
                        Click Here
                      </Button>
                    </form> */}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        : Role == "Player" ?
          <section className="section-auction padding2 bg-img">
            <div class="container" id="mySection">
              <div className="row text-center">
                <h1 className="pt-5 text-white">Register Now to Play</h1>

                <div className="col">
                  <div class="second card padding-section subscrption-card">
                    <div class="title2 text-center">
                      <h1 className=" pb-4 title-text">Xcutta Player</h1>
                      <h2 className="pb-2">
                        Register to play as a Xcutta Player for any Auction or
                        Tournament
                      </h2>

                      <form>
                        <label className="w-100 group-input-feild">
                          <input
                            type="radio"
                            value="1"
                            checked={selectedOption === "1"}
                            class="input-radio-button"
                            onChange={handleOptionChange}
                          />
                          <span class="price-value">$4.99 / Month</span>
                        </label>
                        <p className="or">Or</p>
                        <label className="w-100 group-input-feild">
                          <input
                            type="radio"
                            value="2"
                            class="input-radio-button"
                            checked={selectedOption === "2"}
                            onChange={handleOptionChange}
                          />
                          <span class="price-value">$49 / Year</span>
                        </label>
                        <br />


                      </form>
                      <Row>
                        <Col md={12} className="mb-2">
                          <Button type="submit"  disabled={!selectedOption} onClick={handelSubmitPlayer} className="btn-outline-success-three">
                            Click Here{" "}
                          </Button>
                          <button className="bg-promocode-btn btn btn-primary" onClick={handleCodeModelShow}>Promo Code</button>
                        </Col>
                      </Row>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          :
          <section className="section-auction padding2 bg-img">
            <div class="container" id="mySection">
              <div className="row text-center">
                <h1 className="pt-5 text-white">Register Now to Play</h1>
                <div className="col">
                  <div class="second card padding-section subscrption-card">
                    <div class="title2 text-center">
                      <h1 className="pb-4 title-text">Xcutta Manager</h1>
                      <h2 className="pb-2">
                        Register for free to create your own Sport Auctions, Tournaments, and
                        many more.
                      </h2>
                      <form onSubmit={handelSubmit}>
                        {/* <label className="w-100 group-input-feild">
                          <input
                            type="radio"
                            value="6.99 / Monthly"
                            checked={selectedOption === "6.99 / Monthly"}
                            class="input-radio-button"
                            onChange={handleOptionChange}
                            id="flexRadioDefault2"
                          />
                          <span class="price-value">$6.99 / Month</span>
                        </label>

                        <p className="or">Or</p>

                        <label className="w-100 group-input-feild">
                          <input
                            type="radio"
                            value="69.00 / Yearly"
                            class="input-radio-button"
                            checked={selectedOption === "69.00 / Yearly"}
                            onChange={handleOptionChange}
                            id="flexRadioDefault1"
                          />
                          <span class="price-value">$69 / Year</span>
                        </label>
                        <br /> */}
                        <Button type="submit" className="btn-outline-success-three">
                          Click Here
                        </Button>

                      </form>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div class="second card padding-section subscrption-card">
                    <div class="title2 text-center">
                      <h1 className="pb-4 title-text">Xcutta Player</h1>
                      <h2 className="pb-2">
                        Register to play as a Xcutta Player for any Auction or
                        Tournament
                      </h2>

                      <form>
                        <label className="w-100 group-input-feild">
                          <input
                            type="radio"
                            value="1"
                            checked={selectedOption === "1"}
                            class="input-radio-button"
                            onChange={handleOptionChange}
                          />
                          <span class="price-value">$4.99 / Month</span>
                        </label>
                        <p className="or">Or</p>
                        <label className="w-100 group-input-feild">
                          <input
                            type="radio"
                            value="2"
                            class="input-radio-button"
                            checked={selectedOption === "2"}
                            onChange={handleOptionChange}
                          />
                          <span class="price-value">$49 / Year</span>
                        </label>


                      </form>
                      <br></br>
                      <Row>
                        <Col md={12} className="mb-2">
                          <Button type="submit" disabled={!selectedOption} onClick={handelSubmitPlayer} className="btn-outline-success-three">
                            Click Here{" "}
                          </Button>
                          <button className="bg-promocode-btn btn btn-primary" onClick={handleCodeModelShow}>Promo Code</button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

      }












      <section className="section-auction-sport">
        <Container>
          <h1 className="text-center mt-5 mb-5">Customize your Sports Auction Contest</h1>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            arrows={true}
          >
            <div className="item-slider"><img src="../image/sport/CFL.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Champions Tour.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/College Sports.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/COPA America.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/English FA Cup.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Formula 1 Racing.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Golf.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/LALIGA Soccer.png" ></img></div>

            <div className="item-slider"><img src="../image/sport/LPGA.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Masters.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Men's Tennis.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/MLB.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/MLS.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Nascar.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/NBA.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/NFL.png" ></img></div>

            <div className="item-slider"><img src="../image/sport/la-liga.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/PGA-Tour.png" ></img></div>

            <div className="item-slider"><img src="../image/sport/NHL.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Premier League.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Testing July 29.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/UEFA Champions League Soccer.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/WNBA.png" ></img></div>
            <div className="item-slider"><img src="../image/sport/Women's Tennis.png" ></img></div>

          </Carousel>



        </Container>
      </section>









      <section className="section-auction padding2 registration-sec">
        <div className="container">
          <div className="row text-center">
            <h1 className="pt-5">Powerful Data Collection and Analytics</h1>
            <p class="paragraphtext mb-5">
              The Thrill of Live Auctions with Sports Prognostication
            </p>
            <div className="col mt-5">
              <div className="second card padding-section free-subscription">
                <div className="title2 text-left">
                  <h1 className="pt-4 pb-4 text-regiter">
                    Register for Free as Xcutta Manager <br></br>

                    <span><b>or</b></span>
                    <br />Subscribe as a Xcutta Player
                  </h1>
                  <h2>
                    Gain a comprehensive view of bidding trends, value analytics and Sports Auction history.
                  </h2>

                  <Button
                    className="btn-outline-success-three mt-3"
                    onClick={handleScroll}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div class="card">
                <img src="../image/registration.jpg" className="regid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footertwo />
    </>
  );
};


