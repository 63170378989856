import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import DataTable from 'react-data-table-component';
import { Col, Row, Card, Form, InputGroup } from 'react-bootstrap';
import { GeneratePromoCode, GetPromoCodes } from '../../redux/auth-reducer/auth-service';
import { Footer } from "../../Footer";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { DatePicker } from 'rsuite';
import dayjs from 'dayjs';

export const Generate_PromoCode = () => {
    const [data, setData] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [validity, setValidity] = useState("");
    const [dateValid, setDateValid] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [dataListItem, setDataListItem] = useState([]);
    const[ count,setCount] =useState(0);
    function sidebarfun() {
        setData(!data)
      }
    const handleEndDateChange = (date) => {
        setDateValid(true);
        setEndDate(date);

    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleCode = async (event) => {
        event.preventDefault();
        if (!endDate) {
            setDateValid(false);
            return;
        } else {
            setDateValid(true);

        }

        

        event.preventDefault();
        const formattedEndDate = endDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Use 12-hour format
        });

  
        const endDateObject = new Date(formattedEndDate);

        if (endDateObject < new Date()) {
            toast.error("Your selected time has already expired. Please select a different time.")
         
        }
        else{
            await GeneratePromoCode(formattedEndDate).then((response) => {
         
                if (response?.data?.status == true) {
                    toast.success(response?.data?.message);
                    setCount(count + 1);
                }
                 else if (response?.data?.status == false) {
                    toast.error(response?.data?.message);
                }
                else{
                    toast.error(response?.data?.error);
                }
            })
        }
      

    }

    useEffect(() => {
        const GetData = async () => {
            await GetPromoCodes().then((res) => {
                setDataListItem(res?.data)
            })
        }
        GetData();
    }, [count])

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => index + 1,

        },

        {
            name: 'Promo Code',
            selector: (row) => row?.promoCode,
            sortable: true,
        },
        {
            name: <strong>Start Date</strong>,
            selector: (row) => dayjs(row?.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: <strong>End Date</strong>,
            selector: (row) => dayjs(row?.endDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: <strong>Status</strong>,

            selector: (row) =>
                row?.status === "Ready to share" ? (
                    <>
                        <span style={{ color: "#f9c114" }}>Ready to share</span>
                    </>
                ) : row.status === "Active" ? (
                    <>
                        <span style={{ color: "#043bc7" }}>Active</span>
                    </>
                ) : (
                    <span style={{ color: "red" }}>Expired</span>
                ),
            sortable: true,
        },

    ];

    const filteredData = searchText ? dataListItem?.filter((item) => {
        return item?.promoCode?.toLowerCase().includes(searchText?.toLowerCase()) || item?.status?.toLowerCase().includes(searchText?.toLowerCase())

    })

        : dataListItem;

    return (

        <>
            <ToastContainer />

            <TopNavbar sidebarfun={sidebarfun} />

            <div className="dasboard-group" >
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <Row>
                        <Col md={6}><h1 className="heading-dash">Generate Promo Code</h1></Col>
                    </Row>
                    <Card className='ps-3 pe-3 pb-3 mt-3'>
                        <Form noValidate className='registration_tournament' >
                            <Row>

                                <Form.Group as={Col} md="6 mt-3" controlId="validationCustom02">
                                    <Form.Label>Expiry Date</Form.Label>
                                    <DatePicker
                                        placeholder="End Date"
                                        format="MM/dd/yyyy hh:mm aa"
                                        value={endDate}
                                        disabledDate={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
                                        name="endDate"
                                        onChange={handleEndDateChange}

                                        required
                                    />

                                    <Form.Control.Feedback type="invalid" style={{ display: dateValid ? 'none' : 'block' }}>
                                        Please select the date and time.
                                    </Form.Control.Feedback>

                                </Form.Group>

                            </Row>
                            <Row md="6 mt-3">
                                <Button type="submit" className='update-btn-prof' onClick={handleCode}>Generate</Button>

                            </Row>
                        </Form>

                    </Card>
                    <h1 className="heading-dash mt-4 pb-3">Promo Codes List</h1>
                    <div className='group_data_table'>
                        <div className="table-transaction">
                            <h1 className="subscription_heading ms-4 quries">Queries List</h1>
                            <div className='search-input filter d-flex align-items-center me-4'>
                                <label className='me-3'>Search</label>
                                <input type="text" className='form-control mb-3 mt-3' value={searchText} onChange={handleSearch} />
                            </div>
                            <DataTable

                                columns={columns}
                                data={filteredData}
                                pagination
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                paginationTotalRows={filteredData?.length}
                            />
                        </div>
                    </div>

                </div>

                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}
