import React, { useEffect, useState } from 'react'
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import Chart from "react-apexcharts";
import DataTable from 'react-data-table-component';
import { GetInvoiceDetails } from '../../redux/auth-reducer/auth-service';
import dayjs from 'dayjs';

export function Admin_report() {

  const [data, setData] = useState(false);
  const [showView, setShowView] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [itemListData, setItemListData] = useState([]);
  function sidebarfun() {
    setData(!data)
  }


  useEffect(() => {
    const GetRecentDataInvoice = async () => {
        await GetInvoiceDetails().then((res) => {
          setItemListData(res?.data?.data?.dataList)
       
            console.log(res,"Invoice?????????")

        })
    }
    GetRecentDataInvoice()
},
    []);
    

  // const [options, setoptions] = useState({
  //   chart: {
  //     height: 350,
  //     type: 'area'
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   stroke: {
  //     curve: 'smooth'
  //   },
  //   xaxis: {

  //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
  //   },
  //   tooltip: {
  //     x: {
  //       format: 'dd/MM/yy HH:mm'
  //     },
  //   },
  // });

  // const [series, setseries] = useState([{
  //   name: 'Manager',
  //   data: [31, 40, 28, 51, 42, 109, 100],

  // }, {
  //   name: 'Player',
  //   data: [11, 32, 45, 32, 34, 52, 41],
  // }
  //   , {
  //   name: 'Total Revenue',
  //   data: [10, 30, 40, 38, 36, 20, 41],

  // }],)
  const convertUTCToLocalTime = (utcDateString) => {
    const date = new Date(utcDateString);
    return date.toLocaleString();
};

  const columns = [
    {
      name: 'ID',
      selector: (row,index) =>  index + 1,
      sortable: true,
    },

    {
      name: 'User Type',
      selector: (row) => row?.userType,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row?.emailId,
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: (row) => dayjs(row?.paymentDate).format("MM/DD/YYYY"),
      sortable: true,
    },
  
    {
      name: <strong>Start Time</strong>,
      selector: (row) => {
          const localTime = convertUTCToLocalTime(row?.paymentDate + "Z");
          const [datePart, timePart] = localTime.split(", ");
          return (
              <p>{timePart}</p>
          );
      },
      sortable: true,
  },

    {
      name: 'Amount',
      selector: (row) => row?.amount,
      sortable: true,
    },
    {
      name: 'Plan Type',
      selector: (row) => row?.planType,
      sortable: true,
    },
    
    {
      name: 'Status',
      selector: (row) => (<>
      {row?.status=="Active"? <button className='tablebtn active-status'>Active</button>:<button className='tablebtn bg-dang'>InActive</button>}
      </>
       ),
      sortable: true,
    },
   

  ];

  // const itemListData = [
  //   {
  //     Id: '1',
  //     User_type: 'Manager',
  //     name: 'kailey',
  //     email: 'kailey12@gmail.com',
  //     Joindate: '12/01/2023',
  //     Amount: '100',
  //     plan_type: 'Monthly',
  //     Status: '',
  //     Action: 'View',

  //   },
  //   {
  //     Id: '2',
  //     User_type: 'Player',
  //     name: 'John',
  //     email: 'John@gmail.com',
  //     Joindate: '12/01/2023',
  //     Amount: '100',
  //     plan_type: 'Monthly',
  //     Status: '',
  //     Action: 'View',

  //   },
  //   {
  //     Id: '3',
  //     User_type: 'Manager',
  //     name: 'kailey',
  //     email: 'kailey12@gmail.com',
  //     Joindate: '12/01/2023',
  //     Amount: '100',
  //     plan_type: 'Monthly',
  //     Status: '',
  //     Action: 'View',

  //   },

  // ]
  const viewFn = (viewItem) => {
    setShowView(true);
  }
  const handleClosed = () => {
    setShowView(false);
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = searchText ? itemListData?.filter((item) => {
    return item?.email.toLowerCase().includes(searchText?.toLowerCase()) ||
      item?.plan_type?.toLowerCase().includes(searchText?.toLowerCase()) ||
      item?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      item?.User_type?.toLowerCase().includes(searchText?.toLowerCase())

  })
    : itemListData;
  console.log(itemListData, "itemListData");
  return (
    <>
      <div className="dasboard-group" >
        <TopNavbar sidebarfun={sidebarfun} />
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <h1 className="heading-dash">Report</h1>
          <div className='group_data_table'>

            {/* <div className="subscription chart">
              <Chart options={options} series={series} type="area" width="1000" height="500" />

            </div> */}
            <div className="table-transaction">
              <h1 className="subscription_heading">Invoice</h1>
              <div className='search-input filter d-flex align-items-center me-4'>

                <label className='me-3'>Search</label>
                <input type="text" className='form-control mb-3 mt-3' value={searchText} onChange={handleSearch} />
              </div>
              <DataTable

                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                paginationTotalRows={filteredData?.length}
              />
            </div>
          </div>
        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  )
}

